export default {
    push: null,
	listeners: [],

	onNotification(listener) {
        this.listeners.push(listener);
	},

    subscribe() {
        return new Promise((resolve, reject) => {

            let that = this;

            cordova.plugins.firebase.messaging.requestPermission({forceShow: true}).then(function() {
                console.log("You'll get foreground notifications when a push message arrives");
            });

            if(window.device.platform == 'Android')
            {
                cordova.plugins.firebase.messaging.getToken().then(function(token) {
                    
                    let device = {
                            token:    token,
                            platform: 'gcm',
                            model:    window.device.model,
                            uuid:     window.device.uuid
                        }

                    resolve(device);
                });
            }
            else
            {
                cordova.plugins.firebase.messaging.requestPermission({forceShow: true}).then(function() {
                    cordova.plugins.firebase.messaging.getToken("apns-string").then(function(token) {
                        
                        let device = {
                            token:    token,
                            platform: window.device.platform,
                            model:    window.device.model,
                            uuid:     window.device.uuid
                        }

                        resolve(device);
                    });
                });
            }

            //Mesage in foreground
            cordova.plugins.firebase.messaging.onMessage(function(payload) {});

            //mesage in background
			cordova.plugins.firebase.messaging.onBackgroundMessage(function(payload) {

				that.listeners.forEach(listener => listener(payload));
			});
        });
    },

    unsubscribe() {
        return new Promise((resolve, reject) => {
            // this.push.unregister(resolve, reject);
            cordova.plugins.firebase.messaging.deleteToken().then(function() {
                console.log("Token revoked successfully")
                resolve;
            });
        });
    }
}