<template>
	<div class="phi-post">
		<div class="phi-post-figure" @click="$emit('click', 'figure')">
			<div class="phi-post-image">
				<phi-person-avatar :person="post.author"></phi-person-avatar>
			</div>

			<div class="phi-post-checkbox">
				<mu-icon value="check_box" />
			</div>
		</div>

		<div class="phi-post-header" @click="$emit('click', 'header')">
			<div class="phi-post-author">
				<span class="firstName">{{ post.author.firstName }} </span> <!-- los espacios son necesarios! -->
				<span class="lastName">{{ post.author.lastName }} </span>
				<span class="lastName2">{{ post.author.lastName2 }}</span>
			</div>
			<div class="phi-post-date">{{$t('published')}} {{ $date(post.publishDate) }}</div>
			<div :class="'phi-post-audience-count n_'+post.audienceCount">
				<span v-if="post.audienceCount > 1">{{ $t('notice.ToNPeople', {n: post.audienceCount}) }}</span>
				<span v-if="post.audienceCount == 1 && post.author.id == $store.state.user.id">{{ $t('notice.ToOnePerson') }}</span>
				<span v-if="post.audienceCount == 1 && post.author.id != $store.state.user.id">{{ $t('notice.toMe') }}</span>
			</div>
		</div>

        <slot name="pre-contents"></slot>

		<div class="phi-post-contents">

			<div class="phi-post-event" v-if="post.event" @click="$emit('click', 'event', post.event)">
				<a :href="`${$store.state.url}/calendar/events/${post.event.id}/ics`">
					<mu-icon value="event"></mu-icon>
					<span>{{ eventLabel }}</span>
				</a>
			</div>

			<div class="phi-post-description" @click="$emit('click', 'description')" v-html="post.description"></div>

			<div class="phi-post-blocks">
				<phi-block
					v-for="block in post.blocks"
					:key="block.id"
					:block="block"
				></phi-block>
			</div>

			<div class="phi-post-quotes" v-if="'undefined' !== typeof post.quotes">
				<phi-post
					v-for="(quote, key) in post.quotes"
					:key="quote.id"
					v-model="post.quotes[key]"
					@click="$emit('click-quote', quote, arguments[0])"
				></phi-post>
			</div>

			<div class="trail" v-if="showTrail && trail">
					<button type="button" class="trail-handle" @click="trailIsShown = !trailIsShown">···</button>
					<phi-drawer :open="trailIsShown" linear>
						<phi-post-trail :post="trail"></phi-post-trail>
					</phi-drawer>
			</div>

		</div>
	</div>
</template>

<script>
import PhiPersonAvatar from '@/components/Phi/Person/Avatar.vue';
import PhiPostTrail from './Post/Trail.vue';

export default {
	components: {
		PhiPersonAvatar,
		PhiPostTrail
	},

	model: {
		prop: 'post'
	},

	data() {
		return {
			trail: null,
			trailIsShown: false
		}
	},

	props: {
		post: {
			type: Object,
			required: true
    },

		// Si estamos consultando la ITERACION de una repeticion del evento del post,
		// debemos mostrar las fechas de la iteracion.
		// Recibe un objeto externo {start:XXXXXXXXX, end:XXXXXXXXX}   (XXXXXXXXX = unix timestamps)
		eventDates: {
				type: Object,
				required: false,
				default: null
		},

		showTrail: {
				type: Boolean,
				default: false
		}
	},

	watch: {
		showTrail: {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					this.fetchTrail();
				} else {
					this.trail = null;
				}
			}
		}
	},

	computed: {
		eventLabel() {
			if (!this.post.event) {
				return '';
			}

			let startDay = this.eventDates && this.eventDates.start ? this.eventDates.start : this.post.event.startDate;
			let endDay   = this.eventDates && this.eventDates.end   ? this.eventDates.end   : this.post.event.endDate;

			let allDay    = this.post.event.allDay === true || this.post.event.allDay === '1' || this.post.event.allDay === 1;
			let startDate = this.$date(startDay, "day");
			let startTime = this.$date(this.post.event.startDate, "time");
			let endDate   = this.$date(endDay, "day");
			let endTime   = this.$date(this.post.event.endDate, "time");

			if (allDay) {
				return startDate + (startDate != endDate ? ' - ' + endDate : '');
			} else {
				return startDate + ' ' + startTime + ' - ' + (startDate != endDate ? endDate + ' ' + endTime : endTime);
			}
		}
	},

	methods: {
		fetchTrail() {
			return this.$store.state.api.get(`/posts/${this.post.id}/trail`, {limit: 50})
				.then(trail => this.trail = trail);
		}
	}
}
</script>

<style lang="scss">
$phi-post-segment-padding: 12px;
$phi-post-image-size: 42px;
$phi-post-side-width: $phi-post-image-size + $phi-post-segment-padding*2;

.phi-post {
	display: flex;
	flex-wrap: wrap;

	.trail-handle {
		cursor: pointer;
		border: none;
		border-radius: 4px;
		padding: 0 10px;
    line-height: 16px;
		font-size: 20px;
		font-weight: bold;
		background-color: #ddd;
		color: #777;
		outline: none;
	}


	a {
		background: #eff9ff;
		color: rgb(28, 137, 184);
	}

	& > div {
		padding: $phi-post-segment-padding;
		box-sizing: border-box;
	}

	& > .phi-post-figure {

		.phi-post-image {
			cursor: pointer;

			width: $phi-post-image-size;
			height: $phi-post-image-size;
			border-radius: 4px;
			overflow: hidden;

			img {
				width: 100%;
			}
		}

		.phi-post-checkbox {
			width: $phi-post-image-size;
			height: $phi-post-image-size;

			display: flex;
			align-items: center;
			justify-content: center;
			color: dodgerblue;

			display: none;
		}
	}

	& > .phi-post-header {
		flex: 1;
		padding-left: 0;
		padding-bottom: 0;

		.phi-post-author,
		.phi-post-date {
			display: inline-block;
		}

		.phi-post-author {
			font-weight: 600;
			font-size: .85em;
			color: #444;
			margin-right: .4em;
		}

		.phi-post-date {
			font-size: .75em;
			color: #777;
		}

		.phi-post-audience-count {
			color: #777;
			font-size: .75em;
		}
	}

	& > .phi-post-contents {
		width: 100%;
		padding-top: 0;

		& > .phi-post-description {
			// Estos valores afectan como se ve el post en CUALQUIER otro lugar (i.e. el cuerpo del editor).
			// white-space: pre-wrap;
			// font-size: 0.95em;

			margin-bottom: 5px;
			word-wrap: break-word;

			img {
				max-width: 100%;
				height: auto !important;
			}

			p{
				margin: 16px 0;
			}
		}

		& > .phi-post-event {
			a {
				display: flex;
				align-items: center;

				cursor: pointer;
				text-decoration: none;

				padding: 8px 0;
				font-size: 1em;
				color: #555;

				.mu-icon {
					color: #777;
					margin-right: .4em;
					font-size: 22px;
				}

				&:hover {
					background-color: rgba(0, 0, 0, .04);
				}
			}
		}

		& > .phi-post-blocks {
			& > .phi-block {
				overflow-y: auto;
			}
		}

		& > .phi-post-quotes {
			margin: 12px 0;

			border-left: 4px solid dodgerblue;
			transform-origin: 0 0 0;
			transform: scale(.8);
		}
	}

	&.selected {
		background-color: #edf4fd;

		& > .phi-post-figure {
			.phi-post-image {
				display: none;
			}

			.phi-post-checkbox {
				display: flex;
			}
		}
	}

	/* Short view */
	&.short {
		display: block;
		clear: both;

		& > .phi-post-figure {
			width: $phi-post-side-width;
			float: left;
		}

		& > .phi-post-header,
		& > .phi-post-contents {
			margin-left: $phi-post-side-width;
		}

		& > .phi-post-header {
			.phi-post-date {
				margin-right: .5em;
			}

			.phi-post-audience-count {
				display: inline-block;
			}
		}

		& > .phi-post-contents {
			padding-top: 0;
			padding-left: 0;
			width: auto;
		}
	}

}
</style>