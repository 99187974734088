<template>
    <div v-if="!open">
        <button @click="openPicker">Seleccionar archivo</button>
    </div>
</template>

<script>
var developerKey = 'AIzaSyCpSKazmH7T7ArBkdcPiRYaYG_QKhj7rRM';
var clientId     = "890266961007-mg25j3ihldb07ht1tnhad5aioutqn5jn.apps.googleusercontent.com"
var scope        = ['https://www.googleapis.com/auth/drive.readonly'];
// var scope        = ['https://www.googleapis.com/auth/drive'];

window.onApiLoad = function() {
    gapi.load('auth:picker', () => {
        googlePickerLoadCallbacks.forEach(callback => callback());
    });
}

var googlePickerLoadCallbacks = [];

export default {
    name: "google-picker",

    props: {
        open: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            oauthToken: null,
            picker: null
        };
    },

    mounted() {
        const googleMapScript = document.createElement('SCRIPT');
        googleMapScript.setAttribute('src', 'https://apis.google.com/js/api.js?onload=onApiLoad');
        googleMapScript.setAttribute('async', '');
        googleMapScript.setAttribute('defer', '');
        document.body.appendChild(googleMapScript);

        googlePickerLoadCallbacks.push(this.load);
    },

    watch: {
        open(isOpen) {
            if (isOpen) {
                this.openPicker();
            }
        }
    },

    methods: {
        load() {
            if (this.open) {
                this.openPicker();
            }
        },

        authorize() {
            return new Promise((resolve, reject) => {
                if (this.oauthToken) {
                    resolve();
                    return;
                }

                window.gapi.auth.authorize(
                    {
                        'client_id': clientId,
                        'scope':     scope,
                        'immediate': false
                    },

                    authResult => {
                        if (authResult && !authResult.error) {
                            this.oauthToken = authResult.access_token;
                            resolve();
                        } else {
                            reject(authResult);
                        }
                    }
                );
            });
        },

        openPicker() {
            if (this.picker) {
                this.picker.setVisible(true);
                return;
            }

            this.authorize()
                .then(() => {
                    this.picker = new google.picker.PickerBuilder()
                        .addView(google.picker.ViewId.DOCS)
                        .addView(google.picker.ViewId.DOCUMENTS)
                        .addView(google.picker.ViewId.SPREADSHEETS)
                        // .addView(google.picker.ViewId.PHOTOS)
                        // .addView(new google.picker.DocsUploadView()) // needs scope https://www.googleapis.com/auth/drive
                        .setOAuthToken(this.oauthToken)
                        .setDeveloperKey(developerKey)
                        .setCallback(this.pickerCallback)
                        .build();

                    this.picker.setVisible(true);
                });
        },

        pickerCallback(picker) {
            switch(picker.action) {
                case "picked":
                    if (picker.docs.length) {
                        this.$emit("pick", picker.docs);
                    }
                break;

                case "cancel":
                    this.$emit("cancel");
                break;
            }
        }
    }
}
</script>