export default {
    "common.Accept": "speichern",
    "common.Add": "hinzufügen",
    "common.Cancel": "abbrechen",
    "common.Close": "schließen",
    "common.Delete": "löschen",
    "common.Save": "speichern",

    "common.accept": "speichern",
    "common.add": "hinzufügen",
    "common.cancel": "abbrechen",
    "common.close": "schließen",
    "common.delete": "löschen",
    "common.save": "speichern"
}