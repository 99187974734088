/*
execute.js

"execute" es una funcion que recibe un objeto definiendo una ACCION y sus ARGUMENTOS (opt.)

myAction = {
  "action": "doSomeThing",
  "arguments": {
    "thing": "foo"
  }
}


Se busca un archivo .js en @/modules/cms/actions/[nombre de la accion ("doSomeThing")].js
Ese archivo debe exportar una funcion, que se ejecutará enviando los argumentos "arguments"

Para el ejemplo anterior,  

execute(myAction);

equivale a:

import doSomeThing from  @/modules/cms/actions/[nombre de la accion ("doSomeThing")].js

return doSomeThing({"thing": "foo"});


Binding:
let result = execute(myAction, objectToBindAsThis)

equivale a
let result = doSomeThing({"thing": "foo"}).bind(objectToBindAsThis);

*/

export default function (actionObject, bind) {

  if (!actionObject || typeof actionObject != 'object' || !actionObject.action) {
    throw {
      message: 'cms/functions/execute.js: Invalid action',
      object: actionObject
    };
  }

  let filename = actionObject.action.replace(/\./g, '/');

  return import(`@/modules/cms/actions/${filename}.js`)
    .then(module => module.default)
    .then(imported => {
      if (typeof imported == 'function') {
        return imported.bind(bind)(actionObject.arguments);
      }

      if (typeof imported.handler == 'function') {
        return imported.handler.bind(bind)(actionObject.arguments);
      }

      throw `Invalid function ${actionObject.action}`;
    });

}