<template>
    <div class="phi-block-google-document">
        <section class="default" v-if="action != 'delete'">
            <div v-if="action == 'edit'">
                <google-picker @pick="pick" @cancel="cancel" :open="action == 'edit'"></google-picker>
            </div>
            <iframe v-if="block.url" style="width: 100%; min-height: 500px" :src="block.url"></iframe>
        </section>

        <section class="delete" v-if="action == 'delete'">
            <h1>{{$t('question.deleteThisFile')}}</h1>
            <button type="button" class="phi-button danger" @click="$emit('destroy')">{{$t('action.delete')}}</button>
            <button type="button" class="phi-button cancel" @click="$emit('reset')">{{$t('action.cancel')}}</button>
        </section>
    </div>
</template>

<script>
import GooglePicker from '../../GooglePicker.vue';

export default {

    phiBlock: {
        title: "googleDrive",
        icon: "insert_drive_file",
        actions: {
            edit: {
                title: "selectDocument"
            },
            delete: {
                title: "delete"
            }
        }
    },

    name: "phi-block-google-document",
    components: {GooglePicker},
    props: ["block", "action"],

    methods: {
        pick(documents) {
            this.block.url = documents[0].embedUrl ? documents[0].embedUrl : documents[0].url;
            this.$emit('change');
            this.$emit('reset');
        },

        cancel() {
            if (this.block.url) {
                this.$emit('reset');
            } else {
                this.$emit('destroy');
            }
        }
    }
}
</script>