/*
Validacion de objectos condicionales tipo MONGO:

let data = {
  nombre: 'Santiago',
  apellido: 'Cortes',
  edad: 38,
  registro: {
    temperatura: 37,
    sintomas: ['cabeza', 'garganta'],
    comentarios: 'Me siento bien'
  }
};

validate(data.nombre, {'&like': '%tiago%'})
> true

validate(data.registro.temperatura, {'&lte': 36})
> false

validate(data, {'nombre': '&like': '%tiago%'})
> true

validate(data, {
  '&or': [
    {'nombre': {'&like': '%tiago%'}},
    {'registro.temperatura': {'&lte': 36}}
  ]
})
> true


Para facilitar el procesamiento, las condiciones son convertidas a objetos asi:

{'nombre': '&like': '%tiago%'}
>
{
  field: 'nombre',
  op: 'like',
  args: '%tiago'
}

{
  '&or': [
    {'nombre': {'&like': '%tiago%'}},
    {'registro.temperatura': {'&lte': 36}}
  ]
}
>
{
  field: null,
  op: 'or',
  args: [
    {
      field: 'nombre',
      op: 'like',
      args: '%tiago'
    },
    {
      field: 'registro.temperatura',
      op: 'lte',
      args: '36'
    }
  ]
}
*/
import { getProperty } from './getset.js';

function validate(data, condition) {
  if (!condition) {
    return true;
  }

  return checkCondition(data, parseCondition(condition));
}

function checkCondition(value, condition) {
  let operator = condition.op;
  let field = condition.field ? getProperty(value, condition.field) : value;

  if (typeof operators[operator] == "undefined") {
    return false;
  }

  return operators[operator](field, condition.args);
}


var operators = {
  and(value, args) {
    if (!Array.isArray(args)) {
      return false;
    }

    return args.every(condition => checkCondition(value, condition));
  },

  or(value, args) {
    if (!Array.isArray(args)) {
      return false;
    }

    return args.some(condition => checkCondition(value, condition));
  },

  not(value, args) {
    return !checkCondition(value, args);
  },

  eq(value, args) {

    // Igualdad de arreglos: Son considerados iguales si contienen los mismos valores escalares
    // (sin importar el orden de cada uno)
    if (Array.isArray(value) && Array.isArray(args)) {
      if (value.length != args.length) {
        return false;
      }

      let arr1 = value.concat().sort();
      let arr2 = args.concat().sort();
      return arr1.every((value, index) => value == arr2[index]);
    }

    return value == args;
  },

  neq(value, args) {
    return value != args;
  },

  contains(value, args) {
    if (Array.isArray(args)) {
      return args.includes(value);
    }

    return value.indexOf(args) !== -1;
  },

  beginsWith(value, args) {
    return value.indexOf(args) === 0;
  },

  endsWith(value, args) {
    return value.indexOf(args) === (value.length - args.length);
  },

  gt(value, args) {
    return parseFloat(value) > parseFloat(args);
  },

  gte(value, args) {
    return parseFloat(value) >= parseFloat(args);
  },

  lt(value, args) {
    return parseFloat(value) < parseFloat(args);
  },

  lte(value, args) {
    return parseFloat(value) <= parseFloat(args);
  },

  between(value, args) {
    return parseFloat(args[0]) <= parseFloat(value) && parseFloat(value) <= parseFloat(args[1]);
  },

  hasElement(value, args) {
    let arrValue = Array.isArray(value) ? value : [value];
    return arrValue.includes(args);
  },

  hasAny(value, args) {
    let arrValue = Array.isArray(value) ? value : [value];
    for (let i = 0; i < args.length; i++) {
      if (arrValue.includes(args[i])) {
        return true;
      }
    }

    return false;
  },

  hasAll(value, args) {
    for (let i = 0; i < args.length; i++) {
      if (!value.includes(args[i])) {
        return false;
      }
    }

    return true;
  },

  empty(value, args) {
    if (Array.isArray(value)) {
      return !value.length;
    }

    if (typeof value == "object") {
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    }

    return !value || !value.trim();
  },

  nempty(value, args) {
    return !operators.empty(value, args);
  }
};



/*
Convierte una condicion de la forma:
{"&or": [
  {"firstName": {"&eq": "hola"}},
  {"lastName": {"&eq": "mundo"}}
]}

a la forma

{
  "field": null,
  "op": "or",
  "args": [
    {
      "field": "firstName",
      "op": "eq",
      "args": "hola"
    },
    {
      "field": "lastName",
      "op": "eq",
      "args": "mundo"
    }
  ]
}
*/
function parseCondition(condition, field = null) {
  if (!condition || typeof condition != 'object') {
    throw 'Invalid condition';
  }

  let firstKey = Object.keys(condition)[0];
  let args = condition[firstKey];

  return firstKey[0] == '&'
    ? {
      field,
      op: firstKey.substring(1),
      args: !field ? args.map(c => parseCondition(c)) : args // si no hay atributo, se entiende que al condicion es &and o &or
    }
    : parseCondition(args, firstKey);
}

export default validate