<template>
	<div class="post phi-post-trail" v-if="post && post.author">
          <div class="post-author">{{ post.author.firstName }} {{ post.author.lastName }}</div>
          <div class="post-date">{{ $date(post.publishDate) }}</div>
          <div class="post-description" v-html="post.description"></div>
          <phi-post-trail v-if="post.replyTo" :post="post.replyTo"></phi-post-trail>
      </div>
</template>

<script>
export default {
	name: 'phi-post-trail',
	props: ['post']
}
</script>

<style lang="scss" scoped>
.phi-post-trail {
    font-size: 14px;
    border-left: 2px solid rgba(0, 42, 167, 0.25);
    padding-left: 4px;
    margin-top: 12px;

    .post-author {
        font-weight: bold;
    }

    .post-date {
        font-size: 12px;
        color: #888;
    }

    .post-description {
        white-space: pre-wrap;
    }
}
</style>