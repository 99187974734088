<template>
    <gmap-map
        style="width: 100%; height: 100%; min-height: 600px"
        :center="center"
        :zoom="zoom"
        :settings="settings">
    </gmap-map>
</template>

<script>

import * as VueGoogleMaps from 'vue2-google-maps';
import Vue from "vue";

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyCpSKazmH7T7ArBkdcPiRYaYG_QKhj7rRM",
        libraries: 'geometry',
        v: '3.32'

    }
});

export default {
    name: "PhiMap",

    props: {
        'center': {
            required: true
        },

        'settings': {
            required: false,
            default: () => ({})
        },
        'zoom': {
            required: false,
            default: 10
        }
    },

    data() {
        return {

        };
    }
}
</script>

<style lang="scss" scoped>
</style>