import 'whatwg-fetch';
import JWT from './JWT.js';
import Collection from './Collection.js';

import authenticationPassword from './Authentication/Password.js';
import authenticationGoogle from './Authentication/Google.js';
import authenticationOffice365 from './Authentication/Office365.js';
import authenticationApple from './Authentication/Apple.js';

export default class Client {

	constructor(host) {
		this.host           = host;
		this.token          = null;
		this.payload        = null; // token's payload
		this.isLoading      = false;

		this.authorizationCache  = {};

		this.authenticate = {
			token: async ({token}) => this.setToken(token),
			password: authenticationPassword.bind(this),
			google: authenticationGoogle.bind(this),
			office365: authenticationOffice365.bind(this),
			apple: authenticationApple.bind(this)
		};
	}

	collection(url, parameters) {
		return new Collection(this, url, parameters);
	}

	/* Bearer token */
	setToken(tokenString) {
		if (!tokenString) {
			this.token = null;
			this.payload = null;
			return {
				token: null,
				payload: null
			};
		}

		this.payload = JWT.decode(tokenString);
		if (!this.payload) {
			throw 'Invalid token';
		}

		this.token = tokenString;

		if (this.hosts && this.hosts.v3) {
			this.hosts.v3.setToken(tokenString);
		}

		return {
			token: this.token,
			payload: this.payload
		};
	}

	buildRequest(url, options) {
		let isLocal = true;

		if (url.substring(0, 5) == 'http:' || url.substring(0, 6) == 'https:') {
			url = Client.sanitize(url);
			isLocal = false;
		} else {
			url = this.host + "/" + Client.sanitize(url);
		}

    	let queryString = typeof options.queryString !== "undefined" ? options.queryString : null;
		if (queryString) {
			url += "?" + Client.serialize(queryString);
		}

		if (options.body && typeof options.body != "string" && !(options.body instanceof FormData)) {
			options.body = JSON.stringify(options.body);
		}

		var request = new Request(url, options);

		/* Send token in a Authorization header (unless request specifies its own Authorization header) */
		if (isLocal && this.token && (!options || !options.headers || !options.headers.Authorization)) {
			request.headers.set("Authorization", "Bearer " + this.token);
		}

		return request;
	}

	async fetch(url, options) {
		url = Client.sanitize(url);
		var request = this.buildRequest(url, options);

		var timer = setTimeout(() => {
			this.isLoading = true;
		}, 100);

		const response = await fetch(request)
		clearTimeout(timer);
		this.isLoading = false;

		if (!response.ok) {
			response.json = await response.json()
			throw response
		}

		return response
	}

	get(url, data) {
		return this.fetch(url, {
			method: "GET",
			queryString: data
		})
		.then(response => Client.toJSON(response));
	}

	post(url, data, thirdArg) {
		return this.fetch(url, {
			method: "POST",
			body:   typeof thirdArg != "undefined" ? thirdArg : data,
			queryString: typeof thirdArg != "undefined" ? data : null
		})
		.then(response => Client.toJSON(response));
	}

	put(url, data, thirdArg) {
		return this.fetch(url, {
			method: "PUT",
			body:   typeof thirdArg != "undefined" ? thirdArg : data,
			queryString: typeof thirdArg != "undefined" ? data : null
		})
		.then(response => Client.toJSON(response));
	}

	delete(url, data, thirdArg) {
		return this.fetch(url, {
			method: "DELETE",
			body:   typeof thirdArg != "undefined" ? thirdArg : data,
			queryString: typeof thirdArg != "undefined" ? data : null
		})
		.then(response => Client.toJSON(response));
	}

	patch(url, data, thirdArg) {
		return this.fetch(url, {
			method: "PATCH",
			body:   typeof thirdArg != "undefined" ? thirdArg : data,
			queryString: typeof thirdArg != "undefined" ? data : null
		})
		.then(response => Client.toJSON(response));
	}

	options(url, data) {
		return this.fetch(url, {
			method: "OPTIONS",
			body:   data
		})
		.then(response => Client.toJSON(response));
	}

	allowed(url) {
		let isArray = typeof url != "string";
		let urls    = isArray ? url: [url];

		let foundUrls = {};
		let pendingUrls = [];

		urls.forEach(incomingUrl => {
			let sanitizedUrl = Client.sanitize(incomingUrl);

			if (typeof this.authorizationCache[sanitizedUrl] != 'undefined') {
				foundUrls[incomingUrl] = this.authorizationCache[sanitizedUrl];
			} else {
				pendingUrls.push(incomingUrl);
			}
		});


		if (pendingUrls.length) {
			return this.post('allowed', pendingUrls)
				.then(response => {
					for (var i in response) {
						foundUrls[i] = response[i];
						this.authorizationCache[i] = response[i];
					}

					return isArray ? foundUrls : foundUrls[Object.keys(foundUrls)[0]];
				});
		}

		return new Promise((resolve, reject) => {
			resolve(isArray ? foundUrls : foundUrls[Object.keys(foundUrls)[0]]);
		});
	}

	static toJSON(response) {
		return response.json().catch(() => null);
	}

	static sanitize(url) {
		return url.replace(/^\/+|\/+$/g, '');
	}

	static serialize(obj, prefix) {
		var str = [];
		for(var p in obj) {
			if (obj.hasOwnProperty(p)) {
				var k = prefix ? prefix + '[' + p + ']' : p;
				var v = obj[p];

				if (v == null) {
					continue;
				}

				if (typeof v == 'object') {
					str.push(Client.serialize(v, k));
				} else if (typeof v == 'number' || v.length > 0) {
					str.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
				}
			}
		}

		return str.join('&');
	}

}


/*

For the future:
fetch doesn't support progress events, so we need to implement an upload method using XMLHttpRequest

var xhr = new XMLHttpRequest()
xhr.open('POST', '/uploads')
xhr.onload = function() {
}
xhr.onerror = function() {}
xhr.upload.onprogress = function (event) {
  if (event.lengthComputable) {
    var percent = Math.round((event.loaded / event.total) * 100)
  }
}

*/