import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import localforage from 'localforage';

import Client from '@/libraries/phidias.js/lib/Client/Client.js';
import notifications from '@/libraries/phidias.js/lib/Notifications/Notifications.js';

import threads from './modules/threads.js';
import i18n from './modules/i18n.js';
import calendar from './modules/calendar.js';
import geo from './modules/geo.js';
import navigation from './modules/navigation.js';

// !!! compatibilidad con componentes de la nueva app
// (que internamente usan useSettings)
import settings from '@/app/singletons/settings.js';


export default new Vuex.Store({
    modules: {
        threads,
        i18n,
        calendar,
        geo,
        navigation
    },

    state: {
        version: null,
        isFixed: false,  // "isFixed" indica que es una aplicacion custom y que no se debe permitir cambio de codigo de institucion
        url: null,

        api: null,
        token: null,
        user: null,
        apiClients: [], // Lista de CLIENTs a ser injectados via useApiClients (para uso de mixin useApi)

        settings: null,
        notifications,

        backButtonHandler: null,

        stateBilling: {
            currentTab: 1
        },

        stateCalendar: {
            currentView: "listMonth"
        }
    },

    getters: {
        getSetting(state) {
            return (settingPath, defaultValue = undefined) => {
                let setting = getProperty(state.settings, settingPath);
                return setting !== undefined ? setting : defaultValue;
            }
        }
    },

    mutations: {
        setVersion(state, version) {
            state.version = version;
        },

        setFixed(state, isFixed) {
            state.isFixed = isFixed;
        },

        setUrl(state, url) {
            state.url = url;
        },

        setApi(state, api) {
            state.api = api;
        },

        setApiClients(state, apiClients) {
            state.apiClients = apiClients;
        },

        setSettings(state, incomingSettings) {
            if (!incomingSettings) {
                return;
            }

            state.settings = state.settings == null ? incomingSettings : Object.assign(state.settings, incomingSettings);

            // !!! pasar settings al objeto global "settings" de la nueva app
            Object.assign(settings, state.settings);
        },

        setUser(state, { token, payload }) {
            state.token = token;
            state.user = payload;
        },

        logout(state) {
            if (state.notifications && state.notifications.device && state.notifications.device.uuid) {
                state.api.delete(`people/${state.user.id}/devices/${state.notifications.device.uuid}`);
                state.notifications.unsubscribe();
            }

            state.token = null;
            state.user = null;
            state.api.setToken(null);

            localStorage.clear();

            if (typeof device == "undefined" || typeof device.platform == "undefined")
            {
                
            }
            else {

                setTimeout(()=>{
                    cordova.plugins.exit();
                },800);
            }
        },

        reset(state) {

            state.url = null;
            state.token = null;
            state.user = null;
            state.settings = null;
            localforage.removeItem("phidias.settings");
        },

        onBackButton(state, handler) {
            state.backButtonHandler = handler;
        },

        setBillingTab(state, val) {
            state.stateBilling.currentTab = val;
        },

        setCalendarView(state, val) {
            state.stateCalendar.currentView = val;
        },

        setUserAvatar(state, val) {
            state.user.avatar = val;
            console.log("Valor cambiado: ", val);
        }
    },

    actions: {
        /* setUrl ACTION (not to be confused with setUrl MUTATION) */
        async setUrl(context, url) {
            context.commit("setUrl", url);

            let apiClient = new Client(url);
            apiClient.type = 'v4';

            let v3Url = url.replace('.api', '');
            if (v3Url == url) {
                v3Url = 'http://phidias.local';
            }
            let v3Client = new Client(v3Url);
            v3Client.type = 'phidias';
            apiClient.hosts = { v3: v3Client };

            context.commit("setApi", apiClient);
            context.commit("setApiClients", [apiClient, v3Client]);

            context.dispatch("fetchDictionary");
            await context.dispatch("fetchSettings");
        },

        async fetchSettings(context) {
            if (!context.state.api) {
                return;
            }

            context.commit("setSettings", await localforage.getItem("phidias.settings"));

            return context.state.api.get("/vue/settings")
                .then(incomingSettings => {
                    context.commit('setSettings', incomingSettings);
                    localforage.setItem("phidias.settings", incomingSettings);
                })
                .catch(() => { });  // fail silently
        },

        async authenticate(context, authenticationData) {
            let authenticator;
            switch (authenticationData.method) {
                case "token":
                    authenticator = context.state.api.authenticate.token;
                    break;

                case "google":
                    authenticator = context.state.api.authenticate.google;
                    break;

                case "office365":
                    authenticator = context.state.api.authenticate.office365;
                    break;

                case "apple":
                    authenticator = context.state.api.authenticate.apple;
                    break;

                default:
                    authenticator = context.state.api.authenticate.password;
                    break;
            }

            let tokenData = await authenticator(authenticationData);
            context.commit("setUser", tokenData);

            context.dispatch("ready");

            /* Subscribe to notifications */
            context.state.notifications.subscribe()
                .then(device => context.state.api.post(`people/${context.state.user.id}/devices`, device))
                .catch(error => console.log("vuex: could not subscribe to notifications", error));
        },

        ready(context) {
            // This action is to be used inside modules as an initialization startpoint
        },

        async initialize(context, { url, language, token }) {
            /* Ensure a language */
            if (!language) {
                language = window.navigator.userLanguage || window.navigator.language; // Use browser language
            }

            language && context.dispatch("setLanguage", language);
            url && context.dispatch("setUrl", url);
            token && await context.dispatch("authenticate", { method: "token", token });
        },

        async wakeup(context) {
            let initialData = {
                url: null,
                language: null,
                token: null
            };

            // Primero buscamos datos del objeto phidias en los meta tags de index.html
            let metaTags = getDataFromMetaTags();
            if (metaTags.version) {
                context.commit("setVersion", metaTags.version);
            }

            if (metaTags.url) {
                initialData.url = metaTags.url;
                context.commit("setFixed", true);
            }

            if (metaTags.language) {
                initialData.language = metaTags.language;
            }


            // Luego lo buscamos en almacenamiento persistente
            let storedData = await localforage.getItem("phidias.store");

            if (storedData) {
                if (storedData.url && !initialData.url) {
                    initialData.url = storedData.url;
                }

                if (storedData.token) {
                    initialData.token = storedData.token;
                }

                if (storedData.language) {
                    initialData.language = storedData.language;
                }
            }

            await context.dispatch("initialize", initialData);


            // now we subscribe to changes in the store to handle persistence :)
            let storageTimer = null;

            this.subscribe((mutation, state) => {
                switch (mutation.type) {
                    case "setUrl":
                    case "setLanguage":
                    case "setUser":
                    case "logout":
                    case "reset":

                        clearTimeout(storageTimer);
                        storageTimer = setTimeout(() => {
                            localforage.setItem("phidias.store", {
                                url: state.url,
                                language: state.i18n.language,
                                token: state.token
                            });
                        }, 420);

                        break;
                }
            });
        }
    }
})



function getDataFromMetaTags() {
    let retval = {};
    let prefix = 'phidias';

    /* Obtain data from metatags (in public/index.html) */
    var metas = document.querySelectorAll('meta');
    for (var cont = 0; cont < metas.length; cont++) {
        if (metas[cont].name.substring(0, prefix.length) == prefix) {
            retval = assignProperty(retval, metas[cont].name.substring(prefix.length + 1), metas[cont].content);
        }
    }

    return retval;
}

function assignProperty(object, propertyString, propertyValue) {
    let curObj = object;
    let objectPath = propertyString.split('.');
    let propertyName = objectPath.pop();

    objectPath.forEach(property => {
        if (typeof curObj[property] == 'undefined') {
            curObj[property] = {};
        }
        curObj = curObj[property];
    });

    curObj[propertyName] = propertyValue;
    return object;
}

function getProperty(object, propertyString) {
    if (!object || typeof object != "object") {
        return undefined;
    }

    // Check for property including dot names
    if (typeof object[propertyString] != undefined) {
        return object[propertyString];
    }

    let curObj = object;
    let objectPath = propertyString.split('.');

    for (let k = 0; k < objectPath.length; k++) {
        if (typeof curObj[objectPath[k]] == 'undefined') {
            return undefined;
        }
        curObj = curObj[objectPath[k]];
    }

    return curObj;
}
