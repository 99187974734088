export default {
  "common.Accept": "Accepter",
  "common.Add": "Ajouter",
  "common.Cancel": "abandonner",
  "common.Close": "Fermer",
  "common.Delete": "Supprimer",
  "common.Save": "Sauvegarder",

  "common.accept": "accepter",
  "common.add": "ajouter",
  "common.cancel": "abandonner",
  "common.close": "fermer",
  "common.delete": "supprimer",
  "common.save": "sauvegarder",

	"action.archive": "Archiver",
	"action.delete": "Supprimer",
	"action.markRead": "Marquer comme lu",
	"action.markUnread": "Marquer comme non lu",
	"action.enable": "activer",
	"search": "Recherche",
    "noun.busy": "occupé",
    "events": "événements",
    "assignments": "devoirs",
    "exams": "examens",
    "evaluations": "évaluations",

    "register.continueToProcess": "Continuer",
	"register.form.addRelative": "Ajoutez le relatif",
	"register.form.addStudent": "Ajouter un étudiant",
	"register.form.birthday": "Anniversaire",
	"register.form.cancel": "Annuler",
	"register.form.children": "Enfants",
	"register.form.children2": "Enfants",
	"register.form.course": "Cours",
	"register.form.course.pick": "-- Choisir un cours --",
	"register.form.email": "Email",
    "register.form.document": "Document",
	"register.form.firstName": "Prénom",
	"register.form.gender.female": "Féminin",
	"register.form.gender.male": "Masculin",
	"register.form.gender": "Genre",
	"register.form.lastName": "Nom de famille",
	"register.form.lastName2": "Deuxième nom de famille",
	"register.form.loading": "Enregistrement de ...",
	"register.form.submit": "Registre",
	"register.form.password": "Mot de passe",
	"register.form.success.title": "Vérifiez votre courrier électronique !",
	"register.form.success.email": "Nous avons envoyé un message à <strong>{{email}}</strong> avec des instructions pour continuer",
	"register.form.verifyPassword": "Vérifier le mot de passe",
	"register.form.error.birthday.empty": "Vous devez choisir une date",
	"register.form.error.children.empty": "Vous devez choisir au moins un fils",
	"register.form.error.course.empty": "Vous devez choisir un cours",
	"register.form.error.email.empty": "Vous devez taper un email",
	"register.form.error.email.invalid": "Adresse électronique non valide",
	"register.form.error.email.taken": "Cet email est déjà utilisé",
	"register.form.error.document.empty": "Vous devez taper un document",
	"register.form.error.firstName.empty": "Vous devez taper un nom",
	"register.form.error.lastName.empty": "Vous devez taper un nom de famille",
	"register.form.error.password.empty": "Vous devez saisir un mot de passe",
	"register.form.error.password.mismatch": "Les mots de passe ne correspondent pas",

    "downloading":"Téléchargement",
	"error downloading file":"Erreur lors du téléchargement du fichier",

    "post.settings.recipientsVisible": "Destinataire visible",
    "post.settings.recipientsInvisible": "Destinataire invisible",
    "post.settings.highlightOn": "publié comme « information importante »",
    "post.settings.highlightOff": "non publié comme « information importante »",
    "post.settings.repliesOn": "réponses activées",
    "post.settings.repliesOff": "aucune réponse",
    "post.settings.repliesAllOn" : "Answers for all enabled",
	"post.settings.repliesAllOff" : "Answers for all disabled",
	"post.settings.forwardOn" : "Forward enabled",
	"post.settings.forwardOff" : "Forward disabled",
    "post.settings.notificationsOn": "notifications activées",
    "post.settings.notificationsOff": "notifications désactivées",
    "post.noun.everything": "tout",
    "action.accept": "Accepter",
    "action.add": "Ajouter",
    "action.addCheckpoint": "Ajouter un contrôle",
    "action.addCondition": "Ajouter une condition",
    "action.addNewRule": "Ajouter une règle",
    "action.addToCalendar": "Afficher dans le calendrier",
    "action.allowReplies": "autoriser les réponses",
    "action.allowRepliesAll": "permettre des réponses à tous",
	"action.allowForward": "permettre d'avancer",
    "action.attach": "joindre",
    "action.cancel": "abandonner",
    "action.clearCache": "vider le cache",
    "action.createNewType": "Ajouter un type",
    "action.Download": "télécharger",
    "action.close": "Fermer",
    "action.deselect": "annuler la sélection",
    "action.form.addField": "Ajouter un champ",
    "action.form.RemoveField": "supprimer le champ",
    "action.forward": "Faire suivre le message",
    "action.Forward": "Faire suivre le message",
    "action.Share": "Partager",
    "action.slideGps" : "Demarrage GPS",
    "action.HideRecipients": "Cacher le destinataire",
    "action.highlightPost": "publier comme « message important »",
    "action.logout": "Déconnexion",
    "action.notify": "envoyer des notifications",
    "action.pay": "payer",
    "action.post.addAttachment": "Ajouter une pièce jointe",
    "action.redact": "Rédiger",
    "action.repeatEvery": "Tout répéter",
    "action.repeatOn": "Répéter",
    "action.reply": "répondre",
    "action.replyAll": "répondre à tous",
    "action.restore": "restaurer",
    "action.schedulePublishDate": "Schedule publish date",
    "action.scheduleExpirationDate": "Date d'expiration de l'horaire",
    "action.save": "Sauvegarder",
    "action.select": "sélectionner",
    "action.send": "envoyer",
    "action.undo": "annuler",
    "adj.allDay": "toute la journée",
    "adj.Appreciation.expelled": "expulsé de l'école",
    "adj.Appreciation.mustRepeat": "doit redoubler",
    "adj.Appreciation.promoted": "ajoutée",
    "adj.Appreciation.toDefine": "à définir",
    "adj.ends": "terminé",
    "adj.everyDay": "tous les jours",
    "adj.everyMonth": "chaque mois",
    "adj.everyWeek": "chaque semaine",
    "adj.everyYear": "chaque année",
    "adj.notIssued.plural": "non transmis",
    "adj.read.plural": "lu",
    "adj.repeats": "se répète",
    "adj.selected": "sélectionné",
    "adj.statusActive": "actif",
    "adj.statusInactive": "inactif",
    "adj.statusGraduated": "gradué",
    "adj.statusInscribed": "inscrit",
    "adj.statusPending": "en attente",
    "adj.statusSuspended": "ne passe pas",
    "adj.statusWithdrawn": "Départ au cours de l'année",
    "adj.unread.plural": "non lus",
    "adj.unread.singular": "non lus",
    "atendant": "Tuteur légal",
    "brother": "frère",
    "compose.placeholder": "dites quelque chose…",
    "cumulatives": "accumulé",
    "daughter": "fille",
    "employee": "employé",
    "everyone": "tous",
    "exactly": "exactement",
    "father": "père",
    "graded equal to": "noté",
    "graded greater than or equal to": "note supérieure ou égale à",
    "graded greater than": "note supérieure à",
    "graded less than or equal to": "note inférieure ou égale à",
    "graded less than": "note inférieure à",
    "grades": "Notes",
    "grade": "Note",
    "group director": "Responsable du groupe",
    "less than or exactly": "moins ou exactement",
    "less than": "moins que",
    "member": "membre",
    "more people": "plus de personnes",
    "more than or exactly": "plus ou exactement",
    "more than": "plus que",
    "mother": "mère",
    "nav.calendar": "Calendrier",
    "nav.contacts": "Contacts",
    "nav.highlights": "Annonces",
    "nav.inbox": "Messages",
    "nav.school": "Plus",
    "no": "Non",
    "noone": "personne",
    "notice.loading": "charger",
    "notice.noPeopleSelected": "Veuillez sélectionner au moins un destinataire",
    "notice.readByReadOfTotal": "{{read}} de {{total}} ont lu ce message",
    "notice.thereIsNothingHere": "Aucun résultat",
    "notice.ToOnePerson": "Pour une personne",
    "notice.ToNPeople": "Pour {{n}} personnes",
    "notice.toMe": "pour moi",
    "notice.gpsEnable": "activer le gps",
    "notice.gpsDisable": "désactiver gps",
    "noun.academicGroups": "groupes",
    "noun.academicYear": "année",
    "noun.addEmail": "Ajouter email",
    "noun.administration": "Administration",
    "noun.agenda": "ordre du jour",
    "noun.all.plural": "tous",
    "noun.archived.plural": "archives",
    "noun.Balance": "balance",
    "noun.bcc": "Cci",
    "noun.billing": "facturation",
    "noun.calendar": "calendrier",
    "noun.checkpoints": "Points de contrôle",
    "noun.conceptsToPay": "Engagements ouverts",
    "noun.dashboard": "tableau de bord",
    "noun.day": "jour",
    "noun.dayOfMonth": "Jour du mois",
    "noun.dayOfWeek": "Jour de la semaine",
    "noun.days": "journées",
    "noun.defaultValue": "par défaut",
    "noun.document": "document",
    "noun.editable": "modifiable",
    "noun.Employees": "employé",
    "noun.email": "email",
    "noun.employee-group": "Groupe d'employés",
    "noun.form.description": "description",
    "noun.form.description": "description",
    "noun.form.options": "Options",
    "noun.form.title": "titre",
    "noun.form.typeCheckbox": "case à cocher",
    "noun.form.typeSelect": "liste",
    "noun.form.typeText": "texte",
    "noun.form.typeTextarea": "zone de texte",
    "noun.general": "en général",
    "noun.groups": "groupes",
    "noun.inbox": "boîte de réception",
    "noun.language": "langue",
    "noun.Mentions": "Mentions",
    "noun.modality": "modalité",
    "noun.month": "mois",
    "noun.months": "mois",
    "noun.name": "nom",
    "noun.namePlural": "pluriel",
    "noun.nameSingular": "singulier",
    "noun.never": "jamais",
    "noun.none": "aucun",
    "noun.notifications": "notifications",
    "noun.nounPersonFemale": "la",
    "noun.nounPersonMale": "le",
    "noun.payedConcepts": "Passifs payés",
    "noun.paymentsToApply": "Les paiements à appliquer",
    "noun.pendingCharges": "factures en attente",
    "noun.People": "personnes",
    "noun.periods": "Périodes",
    "noun.post.preview": "Prévisualisation",
    "noun.post.Title": "Objet",
    "noun.postTypes": "Type de publication",
    "noun.prices": "prix",
    "noun.recentPayments": "paiements récents",
    "noun.righNow": "immédiat",
    "noun.Relatives": "parents",
    "noun.section": "classe",
    "noun.sent.plural": "expédié",
    "noun.sentTo.singular": "destinataire",
    "noun.settings": "Configuration",
    "noun.status": "statut",
    "noun.studentCode": "numéro d'étudiant",
    "noun.Students": "Étudiant",
    "noun.to": "à",
    "noun.tracker": "Suivi",
    "noun.transport": "transport",
    "noun.trash": "Corbeille",
    "noun.type": "type",
    "noun.value": "valeur",
    "noun.view": "vue",
    "noun.week": "semaine",
    "noun.weeks": "semaines",
    "noun.years": "années",
    "other": "plus",
    "OK": "ok",
    "person.condition.attributes": "renseignements personnels",
    "person.condition.debtor": "état du paiement",
    "person.condition.enrollment": "inscription",
    "person.condition.type": "type",
    "person.condition.v3Appreciation": "évaluation des inscriptions",
    "person.condition.v3ChildrenWithEnrollment": "Enfants de la classe",
    "person.condition.v3Class": "groupe pédagogique",
    "person.condition.v3Debit": "plan de paiement",
    "person.condition.v3DebitPrice": "Vérifier le prix",
    "person.condition.v3EmployeeGroups": "Groupe (employés)",
    "person.condition.v3FamilyWithXChildren": "Dans les familles avec N enfants ",
    "person.condition.V3Mentions": "nominations",
    "person.condition.v3Role": "rôle",
    "person.condition.v3Transport": "transport",
    "person.condition.V3EnrollmentModality": "modalité",
    "prep.until": "à",
    "published": "publié",
    "question.deleteThisEvent": "supprimer cet événement?",
    "question.deleteThisRule": "supprimer cette règle?",
    "question.discardThisDraft": "annuler ce brouillon",
    "representant": "Responsable",
    "search": "recherche",
    "sister": "sœur",
    "son": "fils",
    "student": "étudiant",
    "teacher": "professeur",
    "substitute teacher": "Enseignant Suppléant",
    "yes": "oui",
    "I did not find anyone": "Aucun résultat de recherche",
    "try again": "essayez à nouveau",
    "uncle": "oncle",
    "aunt": "tante",
    "grandfather": "grand-père",
    "grandmother": "grand-mère",
    "male cousin": "cousin",
    "female cousin": "Cousine",
    "stepfather": "beau-père",
    "stepmother": "belle-mère",
    "nephew": "neveu",
    "niece": "nièce",
    "grandson": "petit-fils",
    "granddaughter": "petite fille",
    "stepson": "beau-fils",
    "stepdaughter": "belle-fille",
    "relative": "Parent",
    "here": "ici",
    "condition.attributes.equalTo": "égal à",
    "condition.attributes.lessThan": "inférieur à",
    "condition.attributes.greaterThan": "supérieur à",
    "condition.attributes.between": "entre",
    "condition.attributes.startsWith": "commence par",
    "condition.attributes.contains": "contient",
    "condition.attributes.firstName": "prénom",
    "condition.attributes.lastName": "nom de famille",
    "condition.attributes.gender": "sexe",
    "condition.attributes.document": "Numéro de pièce d'identité",
    "Total": "total",
    "component.phi.debit.interests": "intérêt",
    "component.phi.debit.issueDate": "date d'émission",
    "component.phi.debit.expirationDate": "date d'expiration",

    "credits.l1": "Développé par Phidias SAS",
    "credits.l2": "pour",
    "credits.l3": "Tous droits réservés",

	"launcher.Website": "Site web",
	"launcher.CallSchool": "Appeler l'école",
	"launcher.Location": "Emplacement",
	"launcher.billing": "Facturation",
	"launcher.messaging": "Messagerie",
	"launcher.transport": "Transport",
    "clinic.blood_type":'Sanguin',
    "clinic.weight": "Poids",
    "clinic.height": "Hauteur",
    "clinic.medical_insurance_EPS": "Assurance médicale et / ou EPS",
    "noun.mobile": "Mobile",
    "Ausencia": "Absence",
    "Aviso Urgente": "Avis urgent",
    "Boletin": "Bulletin",
    "Circular": "Circulaire",
    "clasificado": "classifié",
    "cobro": "Paiement",
    "Comunicado": "Libérer",
    "Documento": "Document",
    "Encuesta": "Sondage",
    "Evento": "Événement",
    "Mensaje": "Message",
    "Noticia": "Des nouvelles",
    "notificacion": "notification",
    "novedad de transporte": "nouveauté de transport",
    "pago": "Payer",
    "proceso": "traiter",
    "reunion": "réunion",
    "seguimiento": "suivi",
    "tarea": "tâche",
    "launcher.classroom": "Classroom",
    "the message is expired": "le message est expiré",

    "privacy policies": "les politiques de confidentialité",
    "read privacy policies": "lire les politiques de confidentialité",
    "attached files": "fichiers joints",
    "accept privacy policies": "accepter les politiques de confidentialité",
    "export to csv": "Exportation vers csv",
}
