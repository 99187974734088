<template>
    <div class="phi-form-editor">
        <phi-input v-model="value.description" :label="$t('noun.form.description')"></phi-input>

        <div class="fields">
            <div class="field" v-for="(field, index) in value.fields" :key="field.id" :data-id="index">

                <div class="field-toolbar phi-media">
                    <div class="phi-media-body">
                        <select v-model="field.type" class="select-type">
                            <option value="text">{{ $t('noun.form.typeText') }}</option>
                            <option value="textarea">{{ $t('noun.form.typeTextarea') }}</option>
                            <option value="select">{{ $t('noun.form.typeSelect') }}</option>
                            <option value="checkbox">{{ $t('noun.form.typeCheckbox') }}</option>
                        </select>
                    </div>
                    <mu-icon class="remover phi-media-right" @click="removeFieldAt(index)" value="close" />
                </div>

                <div class="controls">
                    <phi-input class="input-title" v-model="field.title" :label="$t('noun.form.title')"></phi-input>
                    <phi-input class="input-description" v-model="field.description" :label="$t('noun.form.description')"></phi-input>
                    <textarea class="input-options" v-model="field.options" :placeholder="$t('noun.form.options')" v-show="field.type == 'select'"></textarea>
                </div>

                <div class="preview">
                    <div class="container" :class="'type-'+field.type">
                        <label :for="'field-'+index">{{ field.title }}</label>

                        <input type="text" :id="'field-'+index" v-if="field.type == 'text'" />
                        <input type="checkbox" :id="'field-'+index" v-if="field.type == 'checkbox'" />
                        <textarea :id="'field-'+index" v-if="field.type == 'textarea'"></textarea>
                        <select :id="'field-'+index" v-if="field.type == 'select'">
                            <option> --- </option>
                            <template v-if="field.options">
                                <option v-for="line in field.options.split('\n')">{{ line }}</option>
                            </template>
                        </select>

                        <div class="description">{{ field.description }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="adder phi-card" @click="addField()">{{ $t('action.form.addField') }}</div>

    </div>
</template>

<script>
import Sortable from 'sortablejs';

export default {
    name: "phi-form-editor",

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    methods: {
        sanitize(form) {
            if (typeof form.title == "undefined") {
                this.$set(form, 'title', null);
            }

            if (typeof form.description == "undefined") {
                this.$set(form, 'description', null);
            }

            if (typeof form.fields == "undefined") {
                this.$set(form, 'fields', []);
            }

            form.fields.sort((a, b) => parseInt(a.order) > parseInt(b.order) ? 1 : (parseInt(a.order) < parseInt(b.order) ? -1 : 0));
        },

        addField() {
            this.value.fields.push({
                type:        "text",
                title:       "",
                description: "",
                options:     null,
                order:       this.value.fields.length + 1
            });
        },

        removeFieldAt(index) {
            if (!confirm(this.$t('action.form.RemoveField') + ' ?')) {
                return;
            }
            this.value.fields.splice(index, 1);
        }
    },

    mounted() {
        var vm = this.value;
        this.sanitize(this.value);

		Sortable.create(this.$el.querySelector('.phi-form-editor > .fields'), {
			handle: '.field-toolbar',
			animation: 150,
			forceFallback: true, // it will not work otherwise.  This took me HOURS to discover :(
			onUpdate() {
                var order = 1;
                this.toArray().forEach(index => {
                    vm.fields[index].order = order++;
                });
			}
		});
    }

}
</script>

<style lang="scss">
.phi-form-editor {
    .phi-input {
        display: block;
        padding-top: 12px;
        margin-bottom: 12px;
    }

    .adder {
        cursor: pointer;
        padding: 8px 12px;
    }

    .fields {
        .field {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 18px;

            &.sortable-ghost {
                opacity: .1;
            }

            .field-toolbar {
                cursor: move;
                width: 100%;
                border-radius: 4px;
                background-color: rgba(0,0,0,.05);
                padding: 6px 12px;

                .remover {
                    cursor: pointer;
                    color: #900;
                }
            }

            .controls,
            .preview {
                flex: 1;
                padding: 12px;
            }

            .controls {
                .input-options {
                    width: 100%;
                    min-height: 75px;
                }
            }

            .preview {
                .container {
                    padding: 16px 24px;

                    label {
                        display: block;
                    }

                    .description {
                        font-size: 12px;
                        color: #777;
                    }

                    &.type-checkbox {
                        display: flex;
                        flex-wrap: wrap;

                        input {
                            order: 1;
                            margin-right: 1em;
                        }

                        label {
                            order: 2;
                        }

                        .description {
                            width: 100%;
                            order: 3;
                        }
                    }
                }
            }
        }
    }
}
</style>
