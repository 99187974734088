<template>
    <div class="phi-block-image">
        <img :src="block.url" style="max-width:100%;height:auto">
    </div>
</template>

<script>
export default {

    phiBlock: {
        title: "picture",
        icon: "image",
        actions: {
            edit: {
                title: "modifyPicture"
            }
        }
    },

    name: "phi-block-image",
    props: ["block", "action"]
}
</script>

<style
