export default {
  "common.Accept": "Accettare",
  "common.Add": "Inserisci",
  "common.Cancel": "Annulla",
  "common.Close": "Vicino",
  "common.Delete": "Cancellare",
  "common.Save": "Salvare",

  "common.accept": "accettare",
  "common.add": "inserisci",
  "common.cancel": "annulla",
  "common.close": "vicino",
  "common.delete": "cancellare",
  "common.save": "salvare",

    "action.accept": "Accettare",
    "action.add": "Inserisci",
    "action.addCheckpoint": "Aggiungere checkpoint",
    "action.addCondition": "Aggiungere condizioni",
    "action.addNewRule": "Aggiungere la nuova regola",
    "action.addToCalendar": "Aggiungi al calendario",
    "action.allowReplies": "consentire risposte",
    "action.allowRepliesAll": "consentire risposte a tutti",
    "action.allowForward": "consenti l'inoltro del messaggio",
    "action.addVehicle": "Aggiungi percorso",
    "action.archive": "Archivio",
    "action.attach": "allegare",
    "action.cancel": "Annulla",
    "action.clearCache": "Cancella cache",
    "action.close": "vicino",
    "action.createNewType": "creare un nuovo tipo di",
    "action.delete": "Elimina",
    "action.deselect": "deselezionare",
    "action.enabled": "Abilitare",
    "action.Download": "Scaricare",
    "action.form.addField": "aggiungi campo",
    "action.form.RemoveField": "Eliminare questo campo",
    "action.Forward": "Inoltrare",
    "action.forward": "inoltrare",
    "action.HideRecipients": "Nascondi i destinatari",
    "action.highlightPost": "Aggiungi a Billboard",
    "action.logout": "Disconnettersi",
    "action.markRead": "Mark leggere",
    "action.markUnread": "Segna come da leggere",
    "action.notify": "Notificare",
    "action.pay": "pagare",
    "action.post.addAttachment": "Aggiungi allegato",
    "action.redact": "Comporre",
    "action.repeatEvery": "ripetere ogni",
    "action.repeatOn": "ripetete",
    "action.reply": "rispondere",
    "action.replyAll": "Rispondi a tutti",
    "action.restore": "Ristabilire",
    "action.schedulePublishDate": "Schedule data di pubblicazione",
    "action.scheduleExpirationDate": "Data di scadenza del programma",
    "action.save": "Salvare",
    "action.select": "selezionare",
    "action.send": "Inviare",
    "action.sendAnswers": "Invia le risposte",
    "action.sendAnswer": "Invia le risposta",
    "action.Share": "Condividere",
    "action.slideGps": "Far scorrere per iniziare GPS",
    "action.undo": "Disfare",
    "action.tapHereToUploadFiles": "Tocca qui per caricare i file",
    "adj.allDay": "tutto il giorno",
    "adj.Appreciation.expelled": "espulso",
    "adj.Appreciation.mustRepeat": "deve ripetere",
    "adj.Appreciation.promoted": "promosso",
    "adj.Appreciation.toDefine": "definire",
    "adj.ends": "estremità",
    "adj.everyDay": "ogni giorno",
    "adj.everyMonth": "ogni mese",
    "adj.everyWeek": "ogni settimana",
    "adj.everyYear": "ogni anno",
    "adj.notIssued.plural": "Non emessi",
    "adj.read.plural": "Leggere",
    "adj.repeats": "ripete",
    "adj.selected": "selezionato",
    "adj.statusActive": "Attivo",
    "adj.statusGraduated": "Laureato",
    "adj.statusInscribed": "inscritto",
    "adj.statusPending": "in attesa di",
    "adj.statusSuspended": "Sospeso",
    "adj.statusWithdrawn": "Ritirato",
    "adj.unread.plural": "Non letto",
    "adj.unread.singular": "Non letto",
    "brother": "fratello",
    "component.phi.debit.expirationDate": "Scade",
    "component.phi.debit.interests": "Interessi",
    "component.phi.debit.issueDate": "Rilasciato",
    "compose.placeholder": "Di 'qualcosa ...",
    "condition.attributes.between": "fra",
    "condition.attributes.contains": "contiene",
    "condition.attributes.document": "Documento",
    "condition.attributes.equalTo": "uguale a",
    "condition.attributes.firstName": "nome di battesimo",
    "condition.attributes.gender": "Genere",
    "condition.attributes.greaterThan": "più grande di",
    "condition.attributes.lastName": "Cognome",
    "condition.attributes.lessThan": "meno di",
    "condition.attributes.startsWith": "inizia con",
    "employee": "dipendente",
    "everyone": "tutti",
    "father": "Padre di",
    "group director": "direttore del gruppo",
    "here": "Qui",
    "I did not find anyone": "Non ho trovato nessuno",
    "launcher.Website": "Sito web",
    "launcher.CallSchool": "chiamata Scuola",
    "launcher.Location": "Posizione",
    "member": "Membro di",
    "more people": "più persone",
    "mother": "Madre di",
    "nav.calendar": "Calendario",
    "nav.contacts": "Contatti",
    "nav.highlights": "Mette in risalto",
    "nav.inbox": "Posta in arrivo",
    "nav.school": "Più",
    "no": "No",
    "noone": "nessuno",
    "notice.loading": "Caricamento in corso",
    "notice.noPeopleSelected": "È necessario selezionare destinataries",
    "notice.readByReadOfTotal": "letto da {{read}} di {{total}}",
    "notice.thereIsNothingHere": "Non c'è niente qui",
    "notice.toMe": "per me",
    "notice.ToNPeople": "Per {{n}} persone",
    "notice.ToOnePerson": "Per una persona",
    "notice.tuCompra": "si restituisce da una transazione online, si prega di fornire il sistema qualche minuto per riflettere il risultato",
    "notice.geolocalizationNotSupported": "Geolocalizzazione non è supportato in questo browser",
    "notice.permissionDenied": "Permesso negato",
    "notice.positionNotAvailable": "Posizione non è disponibile",
    "notice.establishingYourPosition": "Stabilire la posizione attuale",
    "notice.generalFailure": "Fallimento generale",
    "notice.gpsSuccessfullEnable": "GPS consentono",
    "notice.gpsEnable": "Abilita GPS",
    "notice.gpsDisable": "Disattiva GPS",
    "notice.gpsLocationAccuracy": "Si prega di girare su loccation (GPS) per migliorare la vostra posizione",
    "notice.errorLocationAccuracy": "Non è possibile richiedere la precisione della posizione",
    "notice.userDeniedPermissionLocation": "Utente ha negato il permesso di utilizzare il servizio di localizzazione",
    "notice.enableLocalizationToGetPosition": "Determinare la posizione",
    "notice.starting a": "Avvio di una",
    "notice.routeFinish": "finire",
    "noun.academicYear": "Anno",
    "noun.administration": "Amministrazione",
    "noun.agenda": "ordine del giorno",
    "noun.all.plural": "Tutti",
    "noun.archived.plural": "Archivio",
    "noun.Balance": "Equilibrio",
    "noun.bcc": "bcc",
    "noun.busy": "occupato",
    "noun.billing": "Fatturazione",
    "noun.calendar": "Calendario",
    "noun.checkpoints": "Punti di controllo",
    "noun.Checked": "verificato",
    "noun.Unchecked": "non verificato",
    "noun.conceptsToPay": "Elementi da pagare",
    "noun.dashboard": "Cruscotto",
    "noun.day": "Giorno",
    "noun.dayOfMonth": "il giorno del mese",
    "noun.dayOfWeek": "giorno della settimana",
    "noun.days": "giorni",
    "noun.defaultValue": "Predefinito",
    "noun.document": "Documento",
    "noun.editable": "Modificabile",
    "noun.Employees": "I dipendenti",
    "noun.email": "E-mail",
    "noun.addEmail": "Aggiungere e-mail",
    "noun.deleteEmail": "Vuoi eliminare questa email e tutte le preferenze di notifica?",
    "noun.validEmail": "L'e-mail non ha un formato valido",
    "noun.files": "File",
    "noun.form": "Modulo",
    "noun.form.description": "descrizione",
    "noun.form.description": "descrizione",
    "noun.form.options": "opzioni",
    "noun.form.title": "titolo",
    "noun.form.typeCheckbox": "casella di controllo",
    "noun.form.typeSelect": "selezionare",
    "noun.form.typeText": "testo",
    "noun.form.typeTextarea": "textarea",
    "noun.general": "Generale",
    "noun.groups": "gruppi",
    "noun.googleDrive": "Google Drive",
    "noun.HTMLText": "Testo HTML",
    "noun.inbox": "Posta in arrivo",
    "noun.language": "linguaggio",
    "noun.Mentions": "menzioni",
    "noun.map": "Carta geografica",
    "noun.modality": "modalità",
    "noun.month": "Mese",
    "noun.months": "mesi",
    "noun.mySubscriptions": "Le mie sottoscrizioni",
    "noun.mobileApp": "App mobile",
    "noun.name": "Nome",
    "noun.namePlural": "Plurale",
    "noun.nameSingular": "Singolare",
    "noun.never": "mai",
    "noun.none": "Nessuna",
    "noun.notifications": "notifiche",
    "noun.nounPersonFemale": "Femmina",
    "noun.nounPersonMale": "Maschio",
    "noun.payedConcepts": "articoli pagato",
    "noun.paymentsToApply": "Pagamenti da applicare",
    "noun.pendingCharges": "le fatture in sospeso",
    "noun.People": "Persone",
    "noun.post.preview": "anteprima",
    "noun.post.modifyFiles": "modificare i file",
    "noun.post.modifyPicture": "modificare foto",
    "noun.post.delete": "Elimina",
    "noun.post.edit": "modificare",
    "noun.post.editVideo": "Modificare video",
    "noun.post.selectDocument": "selezionare il documento",
    "noun.post.Title": "Soggetto",
    "noun.postTypes": "tipi post",
    "noun.prices": "prezzi",
    "noun.recentPayments": "pagamenti recenti",
    "noun.Relatives": "Parenti",
    "noun.righNow": "righ ora",
    "noun.section": "Sezione",
    "noun.sent.plural": "Inviato",
    "noun.sentTo.singular": "Inviato a",
    "noun.settings": "impostazioni",
    "noun.status": "Stato",
    "noun.studentCode": "Codice",
    "noun.Students": "Studenti",
    "noun.to": "A",
    "noun.targets": "Itinerari",
    "noun.tracker": "Tracker",
    "noun.transport": "Trasporto",
    "noun.trash": "Spazzatura",
    "noun.type": "genere",
    "noun.urlVideo": "youtube o vimeo URL",
    "noun.urlVideoError": "Questo non è un URL video valido",
    "noun.value": "valore",
    "noun.view": "vista",
    "noun.video": "video",
    "noun.week": "Settimana",
    "noun.weeks": "settimane",
    "noun.years": "anni",
    "OK": "ok",
    "person.condition.attributes": "persona dettagli",
    "person.condition.debtor": "Debitore",
    "person.condition.enrollment": "Iscrizione",
    "person.condition.type": "genere",
    "person.condition.v3Appreciation": "esito iscrizione",
    "person.condition.v3ChildrenWithEnrollment": "Bambini in iscrizioni",
    "person.condition.v3Class": "Classe",
    "person.condition.v3Debit": "Fatturazione",
    "person.condition.v3DebitPrice": "Fatturazione per prezzo",
    "person.condition.v3EmployeeGroups": "dipendenti del Gruppo",
    "person.condition.v3FamilyWithXChildren": "Nelle famiglie con figli N",
    "person.condition.V3Mentions": "menzioni",
    "person.condition.v3Role": "Ruolo",
    "person.condition.v3Transport": "Trasporto",
    "person.condition.V3EnrollmentModality": "modalità",
    "pick prices": "raccogliere i prezzi",
    "post.noun.everything": "qualunque cosa",
    "post.settings.highlightOff": "Non mostrato in plancia",
    "post.settings.highlightOn": "Mostrato in plancia",
    "post.settings.notificationsOff": "notifiche disattivate",
    "post.settings.notificationsOn": "notifiche abilitati",
    "post.settings.recipientsInvisible": "destinatari nascosti",
    "post.settings.recipientsVisible": "destinatari mostrati",
    "post.settings.repliesOff": "risposte disabilitate",
    "post.settings.repliesOn": "risposte abilitati",
    "post.settings.repliesAllOn" : "Risposte a tutte attivate",
    "post.settings.repliesAllOff" : "Risposte a tutti i disabili",
    "post.settings.forwardOn" : "Reinvia attivato",
    "post.settings.forwardOff" : "Reenviar Inoltra disattivato",
    "prep.until": "fino a",
    "published": "pubblicato",
    "question.deleteThisEvent": "Eliminare questo evento?",
    "question.deleteThisRule": "Eliminare questa regola?",
    "question.deleteThisFile": "Eliminare questo file?",
    "question.discardThisDraft": "Eliminare questo progetto?",
    "question.deleteThisVideo": "Eliminare questo video?",
    "question.deleteThisText": "¿Eliminare questo testo?",
    "question.deleteThisForm": "Eliminare questo modulo?",
    "search": "ricerca",
    "sister": "sorella",
    "teacher": "Insegnante",
    "substitute teacher": "Supplente",
    "Total": "Totale",
    "try again": "riprova",
    "type.Visible": "Mostrato in liste",
    "type.Invisible": "Nascosto dalle liste",
    "yes": "sì",
    "adj.statusInactive": "Non attivo",
    "atendant": "atendant",
    "attendance": "partecipazione",
    "aunt": "zia",
    "daughter": "figlia",
    "female cousin": "cugina",
    "granddaughter": "nipotina",
    "grandfather": "nonno",
    "grandmother": "nonna",
    "grandson": "nipote",
    "male cousin": "cugino",
    "nephew": "nipote",
    "niece": "nipote",
    "noun.employee-group": "Gruppo di Collaborazione",
    "other": "altro",
    "person.condition.V3PeopleSetMemberships": "Gruppo (in tutto)",
    "relative": "parente",
    "representant": "representant",
    "responsible": "responsabile",
    "searching": "ricerca",
    "son": "figlio",
    "startRoute": "Inizia percorso",
    "stepdaughter": "figliastra",
    "stepfather": "patrigno",
    "stepmother": "matrigna",
    "stepson": "figliastro",
    "student": "alunno",
    "uncle": "zio",
    "confirm.deleteFile": "Vuoi davvero eliminare questo attachmen?",
    "events": "eventi",
    "assignments": "assegnazioni",
    "exams": "esami",
    "evaluations": "valutazioni",
    "register.continueToProcess": "Continua",
    "register.form.addRelative": "Aggiungere relativa",
    "register.form.addStudent": "Aggiungere studente",
    "register.form.birthday": "Compleanno",
    "register.form.cancel": "Annulla",
    "register.form.children": "Bambini",
    "register.form.children2": "Bambini",
    "register.form.course": "Corso",
    "register.form.course.pick": "- Scegli un corso -",
    "register.form.email": "e-mail",
    "register.form.document": "Document",
    "register.form.firstName": "nome di battesimo",
    "register.form.gender.female": "Femmina",
    "register.form.gender.male": "Maschio",
    "register.form.gender": "Genere",
    "register.form.lastName": "Cognome",
    "register.form.lastName2": "Secondo cognome",
    "register.form.loading": "Registrazione ...",
    "register.form.submit": "Registrare",
    "register.form.password": "Parola d'ordine",
    "register.form.success.title": "Controlla la tua email!",
    "register.form.success.email": "Noi ve \ mandato un messate a <strong> {{email}} </ strong> con le istruzioni per continuare",
    "register.form.verifyPassword": "Verifica la password",
    "register.form.error.birthday.empty": "È necessario scegliere la data",
    "register.form.error.children.empty": "È necessario scegliere almeno un figlio",
    "register.form.error.course.empty": "È necessario selezionare un corso",
    "register.form.error.email.empty": "È necessario digitare un'eMail",
    "register.form.error.email.invalid": "Indirizzo non valido",
    "register.form.error.email.taken": "Questa email è già in uso",
    "register.form.error.document.empty": "È necessario digitare un documento",
    "register.form.error.firstName.empty": "È necessario digitare un nome",
    "register.form.error.lastName.empty": "È necessario digitare un cognome",
    "register.form.error.password.empty": "È necessario digitare una password",
    "register.form.error.password.mismatch": "le passwords non corrispondono",
    "tracking": "puntamento",
    "transport.email": "e-mail",
    "transport.sms": "sms",
    "transport.apn": "App (iOS)",
    "transport.gcm": "App (Android)",
    "launcher.billing": "Fatturazione",
    "launcher.messaging": "messaggistica",
    "launcher.transport": "Trasporto",
    "downloading": "Download",
    "error downloading file": "Errore durante il download di file",
    "credits.l1": "Sviluppato da Fidia SAS",
    "credits.l2": "per",
    "credits.l3": "Tutti i diritti riservati",
    "action.track": "Track",
    "cumulatives": "Accumulato",
    "exactly": "Esattamente",
    "graded equal to": "qualificato uguale a",
    "graded greater than or equal to": "qualificato con più o uguale a",
    "graded greater than": "qualificato con più di",
    "graded less than or equal to": "qualificato con minore o uguale a",
    "graded less than": "qualificato con meno di",
    "grades": "Note",
    "grade": "Note",
    "less than or exactly": "meno o esattamente",
    "less than": "meno di",
    "more than or exactly": "più o meno esattamente",
    "more than": "più di",
    "noun.academicGroups": "Gruppi",
    "noun.periods": "Periodi",
    "launcher.CallSchool": "Chiama la scuola",
    "launcher.Location": "Location",
    "launcher.billing": "Tesoro",
    "clinic.blood_type": "Tipo di sangue",
    "clinic.weight": "Peso",
    "clinic.height": "Altezza",
    "clinic.medical_insurance_EPS": "Assicurazione medica e / o EPS",
    "noun.mobile": "Celular",
    "Ausencia": "Assenza",
    "Aviso Urgente": "Avviso urgente",
    "Boletin": "Bollettino",
    "Circular": "Circolare",
    "clasificado": "classificato",
    "cobro": "pagamento",
    "Comunicado": "Pubblicazione",
    "Documento": "Documento",
    "Encuesta": "Sondaggio",
    "Evento": "Evento",
    "Mensaje": "Messaggio",
    "Noticia": "Notizia",
    "notificacion": "notifica",
    "novedad de transporte": "novità di trasporto",
    "pago": "pagare",
    "proceso": "processi",
    "reunion": "riunione",
    "seguimiento": "azione supplementare",
    "tarea": "compito",
    "launcher.classroom": "Aula",
    "the message is expired": "il messaggio è scaduto",

    "privacy policies": "le informative sulla privacy",
    "read privacy policies": "leggi le informative sulla privacy",
    "attached files": "files allegati",
    "accept privacy policies": "accettare le politiche sulla privacy",
    "export to csv": "Esportazione in csv",
}