export default class PhidiasColorUtils {
    constructor() {}

    static isValidCssColour(stringToTest) {
       if (stringToTest === ""){
            return false;
        }
        if (stringToTest === "inherit"){
            return false;
        }
        if (stringToTest === "transparent"){
            return false;
        }

        var isValid = false;

        var image = document.createElement("img");
        image.style.color = "rgb(0, 0, 0)";
        image.style.color = stringToTest;
        if (image.style.color !== "rgb(0, 0, 0)"){
            isValid = true;
        }

        if(!isValid){
            image.style.color = "rgb(255, 255, 255)";
            image.style.color = stringToTest;
            isValid =  image.style.color !== "rgb(255, 255, 255)";
        }

        return isValid;
    }

    static getTextColorForBG(hexcolor){
        if (!hexcolor) {
            return "#000";
        }

        //*******************************
        //working only with hex colors by now :S
        //*******************************

        if(hexcolor[0] != "#"){
            hexcolor = "#" + hexcolor;
        }

        if(hexcolor.length < 4 || hexcolor.length > 7 ){
            return "#000";
        }

        if(hexcolor.length === 4){
            hexcolor = String(hexcolor[0]) + String(hexcolor[1]) + String(hexcolor[1]) + String(hexcolor[2]) + String(hexcolor[2]) + String(hexcolor[3]) + String(hexcolor[3]);
        }

        if(this.isValidCssColour(hexcolor)){
            let r = parseInt(hexcolor.substr(1,2),16);
            let g = parseInt(hexcolor.substr(3,2),16);
            let b = parseInt(hexcolor.substr(5,2),16);

            let yiq = ((r*299)+(g*587)+(b*114))/1000;
            return (yiq >= 128) ? "#000" : "#fff";
        }else{
            return "#000";
        }

    }

    static createColorGradient(frequency1, frequency2, frequency3,phase1, phase2, phase3,center, width, len){
        //from http://krazydad.com/tutorials/makecolors.php

        if(center == undefined){
            center = 128;
        }

        if(width == undefined){
            width = 127;
        }

        if(len == undefined){
            len = 50;
        }

        var result = [];

        for (var i = 0; i < len; ++i){
            let red = Math.sin(frequency1*i + phase1) * width + center;
            let green = Math.sin(frequency2*i + phase2) * width + center;
            let blue = Math.sin(frequency3*i + phase3) * width + center;

            result.push( "#" +  ("0" + parseInt(red,10).toString(16)).slice(-2) +  ("0" + parseInt(green,10).toString(16)).slice(-2) +  ("0" + parseInt(blue,10).toString(16)).slice(-2));
        }

        return result;
    }

    static getColorsList(numberOfColors){
        //use predefined values to get a list of different colors, acording to http://krazydad.com/tutorials/makecolors.php

        //return this.createColorGradient(1.666, 2.666, 3.666, 0, 0, 0, 200, 60, numberOfColors);
        // ^^ this is a dynamic method for big ammounts of colors, but produced colors are not nice, so i choose to pick a limited but nicer pallette

        // return ["#e6194b", "#3cb44b", "#0082c8", "#f58231", "#911eb4", "#46f0f0", "#f032e6", "#d2f53c", "#fabebe", "#008080", "#e6beff", "#aa6e28", "#fffac8", "#800000", "#aaffc3", "#808000", "#ffd8b1", "#000080", "#808080", "#FFFFFF", "#ffe119", "#000000"];
        return ['#3c6478', '#43abc9', '#b5c689', '#bca136', '#c2571a', '#902617'];
    }
}

