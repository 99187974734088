/* Modulo de vuex para administrar el posicionamiento GPS */
import GeoTracker from '@/libraries/phidias.js/lib/Geo/Tracker.js';

export default {
    state: {
        tracker: null,
        lastPostDate: null
    },

    mutations: {
        setTracker(state, trackerInstance) {
            state.tracker = trackerInstance;
            state.lastPostDate = null;
        },

        setLastPostDate(state, date) {
            state.lastPostDate = date;
        }
    },

    actions: {
        // async initialize() {
        //     console.log("geo.js initialize()");
        //     await new Promise(resolve => setTimeout(resolve, 3000));
        //     console.log("geo.js is done now");
        // },

        async enable(context, targetId) {
            let tracker = new GeoTracker(targetId);

            tracker.onPosition(position => {
                // Debounce: dont run handler less than 2s apart
                let now = new Date().getTime();
                if (context.state.lastPostDate && (now - context.state.lastPostDate) <= 2000) {
                    return;
                }
                context.commit("setLastPostDate", now);

                // Push position to API
                if (!context.rootState.api) {
                    return;
                }

                let payload = {
                    latitude: position.coords.latitude, // + (Math.random() * 0.005),
                    longitude: position.coords.longitude, // + (Math.random() * 0.005),
                    altitude: position.coords.altitude,
                    accuracy: position.coords.accuracy,
                    altitudeAccuracy: position.coords.altitudeAccuracy,
                    heading: position.coords.heading,
                    speed: position.coords.speed,
                    timestamp: Math.round(position.timestamp / 1000)
                };

                context.rootState.api.post(`geo/targets/${targetId}/position`, payload);
            });

            await tracker.enable();

            context.commit("setTracker", tracker);
        },

        async disable(context) {
            await context.state.tracker.disable();
            context.commit("setTracker", null);
        }
    }
}