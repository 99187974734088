import Client from './Client.js';
import localforage from 'localforage';

export default class Collection {

    constructor(client, url, parameters = {}) {
        this.client     = client;
        this.url        = url;
        this.parameters = parameters;
        this._items     = [];
        this._response  = null;
        this.isLoading  = false;
        this.dummy = false;
        this.reset();
    }

    reset() {
        this.meta       = {};
        this.countCache = {};
        this.total      = null;
        this.pagination = {
            page: 1,
            limit: this.parameters.limit ? this.parameters.limit : 20,
            hasNext: false
        };
    }

    /* Clear this collection from cache */
    clear() {
        return this.client.clear(this.url);
    }

    /* Return a distinct collection id (string) */
    getUniqueId() {
        return btoa(this.url + Client.serialize(this.parameters));
    }

    async fetch(parameters = this.parameters, append = false) {
        this.isLoading = true;
        !append && this.reset();
        this.parameters = parameters;

        let collectionId = this.getUniqueId();

        try {
            let storedItems = await localforage.getItem(`phidias.collection.${collectionId}`);
            if (storedItems) {
                this.appendItems(storedItems);
            }
        } catch (e) {
            // shhhhhhh, sshhhh...
        }

        return this.client
            .fetch(this.url, {
                method: "get",
                queryString: Object.assign({}, parameters, this.pagination),
                caching: false
            })
            .then(response => {
                this.isLoading = false;
                this._response = response;
                this.total     = response.headers.get("x-phidias-collection-total");
                return response.json();
            })
            .then(items => {
                if (this.total > 0) {
                    this.pagination.hasNext = this.pagination.page < Math.ceil(this.total/this.pagination.limit); // Match.ceil(...) is the total number of pages
                } else {
                    this.pagination.hasNext = items.length > 0;
                }

                !append && (this._items = []);
                return this.appendItems(items);
            })
            .then(items => {
                localforage.setItem(`phidias.collection.${collectionId}`, this._items);
                return items;
            });
    }

    next() {
        this.pagination.page++;
        return this.fetch(this.parameters, true);
    }

    appendItems(items) {
        items.forEach(item => this.push(item));
        return items;
    }

    push(incomingItem) {
        for (var i = 0; i < this._items.length; i++) {
            if (Collection.areEqual(incomingItem, this._items[i])) {
                this._items[i] = Collection.merge(this._items[i], incomingItem);
                return this._items[i];
            }
        }

        this._items.push(incomingItem);
        return incomingItem;
    }

    unshift(incomingItem) {
        for (var i = 0; i < this._items.length; i++) {
            if (Collection.areEqual(incomingItem, this._items[i])) {
                this._items[i] = Collection.merge(this._items[i], incomingItem);
                return this._items[i];
            }
        }

        this._items.unshift(incomingItem);
        return incomingItem;
    }

    splice(outgoing) {
        this._items.splice(this.indexOf(outgoing), 1);
    }

    indexOf(item) {
        for (var i = 0; i < this._items.length; i++) {
            if (Collection.areEqual(item, this._items[i])) {
                return i;
            }
        }
        return -1;
    }

    getItem(itemId) {
        var index = this.indexOf(itemId);
        return index == -1 ? null : this._items[index];
    }

    /* Meta-data handling */
    setMeta(item, key, value) {
        if (Array.isArray(item)) return item.forEach(subitem => this.setMeta(subitem, key, value));

        var itemId = this.constructor.getId(item);
        if (this.meta[itemId] == undefined) {
            this.meta[itemId] = {};
        }
        this.meta[itemId][key] = value;

        this.dummy = !this.dummy; //VUE will notice this change and trigger updates in all functions that contain this.dummy
    }

    getMeta(item, key, defaultValue = null) {
        var itemId = this.constructor.getId(item);
        return this.meta[itemId] && this.meta[itemId][key] ? this.meta[itemId][key] : defaultValue;
    }

    show(item) {
        this.setMeta(item, 'hidden', false);
        this.countCache = {};
    }

    hide(item) {
        this.setMeta(item, 'hidden', true);
        this.countCache = {};
    }

    isHidden(item) {
        return this.getMeta(item, 'hidden', false);
    }

    toggle(item) {
        this.isHidden(item) ? this.show(item) : this.hide(item);
    }

    /* Tagging */
    tag(item, tag) {
        if (Array.isArray(item)) return item.forEach(subitem => this.tag(subitem, tag));

        var tags = this.getMeta(item, 'tags', []);
        if (tags.indexOf(tag) == -1) {
            tags.push(tag);
            this.setMeta(item, 'tags', tags);
        }

        delete this.countCache[tag];
    }

    untag(item, tag) {
        if (Array.isArray(item)) return item.forEach(subitem => this.untag(subitem, tag));

        var tags = this.getMeta(item, 'tags', []);
        var index = tags.indexOf(tag);
        if (index != -1) {
            tags.splice(index, 1);
            this.setMeta(item, 'tags', tags);
        }

        delete this.countCache[tag];
    }

    hasTag(item, tag) {
        var tags = this.getMeta(item, 'tags', []);
        return tags.indexOf(tag) != -1;
    }

    toggleTag(item, tag) {
        if (Array.isArray(item)) return item.forEach(subitem => this.toggleTag(subitem, tag));
        this.hasTag(item, tag) ? this.untag(item, tag) : this.tag(item, tag);
    }

    tagged(tag) {
        return this.items.filter(item => this.hasTag(item, tag));
    }

    count(tag) {
        if (this.countCache[tag] != undefined) return this.countCache[tag];

        var count = 0;
        for (var id in this.meta) {
            if (!this.meta[id].hidden && this.meta[id].tags && this.meta[id].tags.indexOf(tag) != -1) count++;
        }

        this.countCache[tag] = count;
        return count;
    }

    //  Adds new properties from incomingObject into targetObject. Keeps all targetObject properties intact.
    static merge(targetObject, incomingObject) {
        // let objA = JSON.parse(JSON.stringify(targetObject));
        // let objB = JSON.parse(JSON.stringify(incomingObject));
        // return Object.assign(objA, objB);
        return Object.assign(targetObject, incomingObject);
    }

    static areEqual(itemA, itemB) {
        return Collection.getId(itemA) == Collection.getId(itemB);
    }

    static getId(item) {
        if (typeof item != "object") return item;
        return item.id ? item.id : (item.singular ? item.singular : JSON.stringify(item));
    }

    /* Pseudo properties */
    get items() {
        this.dummy; // VUE will assume this function must be refreshed when dummy changes
        return this._items.filter(item => !this.isHidden(item));
    }

    get length() {
        return this.items.length;
    }

    get hasNext() {
        return this.pagination.hasNext;
    }

    get isEmpty() {
        return !this.length && !this.isLoading;
    }

    get loading() {
        return this.isLoading && !this.length;
    }

    /* Dig into the cache and overwrite the data :) */
    override() {
        let collectionId = this.getUniqueId();
        localforage.setItem(`phidias.collection.${collectionId}`, this._items);
    }

}