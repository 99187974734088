import Vue from 'vue';

import langEn from '@/modules/i18n/languages/en.js';
import langDe from '@/modules/i18n/languages/de.js';
import langEs from '@/modules/i18n/languages/es.js';
import langFr from '@/modules/i18n/languages/fr.js';
import langIt from '@/modules/i18n/languages/it.js';

import { interpolate } from '@/modules/cms/functions'

const i18n = new Vue({

  // We use data as an object because this is a SINGLETON vue instance
  data: {
    language: 'es-CO',
    fallbackLanguage: 'en',

    dictionary: {
      en: langEn,
      de: langDe,
      es: langEs,
      fr: langFr,
      it: langIt
    }
  },

  methods: {
    setLanguage(language) {
      this.language = language.replace('_', '-'); // reemplaza i.e: es_CO por es-CO
    },

    getTranslation(language, word, params = null, defaultValue = null) {

      // Palabra no encontrada:
      if (typeof this.dictionary[language] == 'undefined' || typeof this.dictionary[language][word] == 'undefined') {

        // Buscar en idioma base  (ej, si el idioma es "es-CO", buscar en "es")
        let baseLanguage = language.substring(0, 2);
        if (baseLanguage != language) {
          return this.getTranslation(baseLanguage, word, params, defaultValue);
        }

        if (language == this.fallbackLanguage) {
          console.warn(`i18n: Cannot find translation for '${word}'`);
          return defaultValue ? defaultValue : word;
        } else { // Fallback language
          return this.getTranslation(this.fallbackLanguage, word, params, defaultValue);
        }
      }

      if (params) {
        return interpolate(this.dictionary[language][word], params);
      }
      return this.dictionary[language][word];
    },

    /* Agrega definiciones sin sobreescribir existentes */
    patch(dictionary) {
      for (let lang in dictionary) {
        if (dictionary.hasOwnProperty(lang)) {
          if (typeof this.dictionary[lang] == 'undefined') {
            this.dictionary[lang] = {};
          }

          this.dictionary[lang] = Object.assign({}, dictionary[lang], this.dictionary[lang]);
        }
      }
    },

    /* Agrega definiciones sobreescribiendo existentes */
    override(dictionary) {
      for (let lang in dictionary) {
        if (dictionary.hasOwnProperty(lang)) {
          if (typeof this.dictionary[lang] == 'undefined') {
            this.dictionary[lang] = {};
          }

          this.dictionary[lang] = Object.assign({}, this.dictionary[lang], dictionary[lang]);
        }
      }
    }
  }
});

export default i18n