<template>
    <div class="phi-person-avatar">
        <div class="avatar" :class="{fliped: fliped}">
            <div class="avatar-side front-side">
                <img v-if="imageUrl" :src="imageUrl" :alt="name">

                <phi-letter-avatar
                    v-else
                    :name="props.name"
                    :id="props.id"
                    :size="realSize"
                ></phi-letter-avatar>
            </div>
            <div class="avatar-side back-side">
                <slot name="back"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import PhiLetterAvatar from '../LetterAvatar.vue';

export default {
    name: "phi-person-avatar",
    components: {PhiLetterAvatar},

	data() {
        return {
            realSize: 40,

            props: {
                name: this.person ? this.getFullName(this.person) : this.name,
                id: this.person ? this.person.id : this.id,
                image: this.person ? this.person.avatar : this.image
            }
        }
    },

	props: {
        person: {
            type: Object
        },

        name: {
            type: String,
            validator: value => value.length > 0
        },

        id: {},

        image: {
            type: String,
            default: ''
        },

        size: {
            default: "42"
        },

        shape: {
            type: String,
            default: "square",
            validator: value => ["square", "circle"].indexOf(value) >= 0
        },

        fliped: {
            type: Boolean,
            default: false
        }
	},

    computed: {
        imageUrl() {
            if(typeof this.props.image === 'string' && !this.props.image.includes("default_person.png") && this.props.image.trim().length > 0) {
                return this.props.image;
            }
            return null;
        }
    },

	methods: {
        initialize() {
            let parentW = window.getComputedStyle(this.$el.parentElement).getPropertyValue("width");
            let parentH = window.getComputedStyle(this.$el.parentElement).getPropertyValue("height");

            this.realSize = Math.min(parseInt(parentW), parseInt(parentH), parseInt(this.size));
            this.$el.style.setProperty('--avatar-width', this.realSize + "px");
        },

        setShape() {
            switch(this.shape) {
                case 'square':
                     this.$el.style.setProperty('--b-radius', "6px");
                break;

                case 'circle':
                    this.$el.style.setProperty('--b-radius', "50%");
                break;
            }
        },

        getFullName(person) {
            let fullName = "";

            if(person.hasOwnProperty("firstName") && person.firstName) {
                fullName = fullName.concat(person.firstName);

                if(person.hasOwnProperty("lastName") && person.lastName) {
                    fullName = fullName.concat(" ").concat(person.lastName);
                }

                if(person.hasOwnProperty("lastName2") && person.lastName2) {
                    fullName = fullName.concat(" ").concat(person.lastName2);
                }
            }

            if(person.hasOwnProperty("firstname") && person.firstname) {
                fullName = fullName.concat(person.firstname);

                if(person.hasOwnProperty("lastname") && person.lastname) {
                    fullName = fullName.concat(" ").concat(person.lastname);
                }

                if(person.hasOwnProperty("lastname2") && person.lastname2) {
                    fullName = fullName.concat(" ").concat(person.lastname2);
                }
            }

            return fullName;
        }
	},

    mounted () {
        this.initialize();
        this.setShape();
    }
}
</script>

<style lang="scss">
.phi-person-avatar {
    --avatar-width: 0;
    --b-radius: 0;

    perspective: 600px;

    display: inline-block;
    width: var(--avatar-width);
    height: var(--avatar-width);
    min-width: var(--avatar-width);
    min-height: var(--avatar-width);
    max-width: var(--avatar-width);
    max-height: var(--avatar-width);
    border-radius: var(--b-radius);
    overflow: hidden;

    img {
        width: 100%;
        max-width: 100%;
    }

    .avatar {
        width: 100%;
        height: 100%;
        position: relative;
        transition: transform 0.15s;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        
        &.fliped {
            transform: rotateY(180deg);
        }

        .avatar-side {
            position: absolute;
            height: 100%;
            width: 100%;
            backface-visibility: hidden;
        }

        .back-side {
            transform: rotateY(180deg);
        }
    }
}
</style>