<template>
	<div class="phi-block-youtube">

		<section class="default" v-if="!action">
			<iframe
				v-if="video.id"
				width="100%"
				height="420"
				:src="videoSource == 'youtube' ? 'https://www.youtube.com/embed/' + video.id : 'https://player.vimeo.com/video/' + video.id + '?color=ffffff&title=0&byline=0&portrait=0'"
				frameborder="0"
				webkitallowfullscreen
				mozallowfullscreen
				allowfullscreen
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
			>
			</iframe>
			<!-- <object>
				<param name="movie" :value="'https://www.youtube.com/v/' + video.id"></param>
				<param name="allowFullScreen" value="allowFullScreen"></param>
				<param name="allowScriptAccess" value="always"></param>
				<embed style="width: 100%; min-height: 300px" :src="'https://www.youtube.com/v/' + video.id" type="application/x-shockwave-flash" allowfullscreen="allowFullScreen" allowScriptAccess="always"></embed>
			</object> -->

			<!-- https://stackoverflow.com/questions/1361149/get-img-thumbnails-from-vimeo -->
		</section>

		<section class="edit" v-if="action == 'edit'">
			<phi-input :label="$t('noun.urlVideo')" v-model="block.url"></phi-input>
			<div class="warning" v-show="block.url && !video.id">{{$t('noun.urlVideoError')}}</div>
			<img
				v-if="video.id"
				:src="videoSource == 'youtube' ? 'https://img.youtube.com/vi/' + video.id + '/0.jpg' : video.foto"
			/>

			<footer>
				<button type="button" class="phi-button" @click="save()" :disabled="!block.url || !video.id">{{$t('action.accept')}}</button>
				<button type="button" class="phi-button cancel" @click="cancel()">{{$t('action.cancel')}}</button>
			</footer>
		</section>

		<section class="delete" v-if="action == 'delete'">
			<h1>{{$t('question.deleteThisVideo')}}</h1>
			<button type="button" class="phi-button danger" @click="$emit('destroy')">{{$t('action.delete')}}</button>
			<button type="button" class="phi-button cancel" @click="$emit('reset')">{{$t('action.cancel')}}</button>
		</section>

		<!--<template v-if="action == 'info'">
			<strong>thumbnail</strong>
			<img v-if="video.id" :src="'https://img.youtube.com/vi/' + video.id + '/0.jpg'" />
		</template>-->

	</div>
</template>

<script>
import PhiInput from '../Input.vue';

export default {

	phiBlock: {
		title: "video",
		icon: "movie",
		actions: {
			edit: {
				title: "editVideo"
			},
			delete: {
				title: "delete"
			}
		}
	},

	name: "phi-block-youtube",
	components: {PhiInput},
	props: ["block", "action"],

	data() {
		return {
			initialUrl: null,
			videoSource: null
		}
	},

	mounted() {
		this.initialUrl = this.block.url;
	},

	methods: {
		save() {
			this.$emit('change');
			this.$emit('reset');
		},

		cancel() {
			this.block.url = this.initialUrl;
			if (!this.block.url) {
				this.$emit("destroy");
			} else {
				this.$emit("reset");
			}
		}
	},

	computed: {
		video: function() {
			// test https://regexr.com/
			var url = this.block.url;
			if (!url || !url.trim().length) {
				return {};
			}

			var sourceRegExp = /^.*(vimeo|youtube|youtu).*/;
			var match = url.match(sourceRegExp);

			if (!match) {
				return {};
			}

			this.videoSource = match[1] == "youtube" || match[1] == "youtu" ? "youtube" : "vimeo";

			var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|vimeo.com\/|player.vimeo.com\/video\/|vimeo.com\/.*\/)([^.\/]*)$.*/;
			var match  = url.match(regExp);

			if (match && match[2].length == 11 || /^vimeo\.com/.test(match[1])) {
				var logo = match[1] == 'youtu.be/' ? 'http://core.phidias.co/interface/xhtml/img/yt_logo_rgb_light.png' : 'http://core.phidias.co/interface/xhtml/img/vimeo.png';
				return {id: match[2], foto: logo};
			} else {
				return {};
			}
		}
	}
}
</script>

<style scoped lang="scss">

section.edit {

	text-align: center;

	.phi-input {
		display: block;
		margin-top: 24px;
	}

	img {
		margin-top: 24px;
	}

	.warning {
		font-size: 0.9em;
		color: #d9534f;
		text-align: left;
	}

	footer {
		margin-top: 24px;
	}


}

</style>
