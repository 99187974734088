export default {
    "common.Accept": "Accettare",
    "common.Add": "Inserisci",
    "common.Cancel": "Annulla",
    "common.Close": "Vicino",
    "common.Delete": "Cancellare",
    "common.Save": "Salvare",

    "common.accept": "accettare",
    "common.add": "inserisci",
    "common.cancel": "annulla",
    "common.close": "vicino",
    "common.delete": "cancellare",
    "common.save": "salvare",
}