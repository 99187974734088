import lang from './languageGeoTracker.js';

//https://github.com/katzer/cordova-plugin-local-notifications
export default class GeoTracker {

	constructor(id, language = "es") {
		this.id              = id;
		this.isEnabled       = false;
		this.watcher         = null;
		this.history         = [];
		this.lastPosition    = null;
		this.successHandlers = [];
		this.errorHandlers   = [];
		this.language        = language;
		this.options         = {
			enableHighAccuracy: true,
			maximumAge: 5000,
			timeout: 10000
		};
	}

	setId(id) {
		this.id = id;
	}

	setLanguage(language) {
		this.language = language;
	}

	getTranslation(text) {
		return lang[this.language][text];
	}

	onPosition(handler) {
		this.successHandlers.push(handler);
	}

	onError(handler) {
		this.errorHandlers.push(handler);
	}

	enable() {
		return new Promise((resolve, reject) => {
			this.requestLocationPermission()
				.then(() => {

					this.enableBackgroundMode();

					if (typeof device != "undefined" && device.platform == "iOS") {
						window.plugins.insomnia.keepAwake();
					}

					navigator.geolocation.clearWatch(this.watcher);

					cordova.plugins.backgroundMode.isScreenOff(function(ScreenOff) {
    					if (ScreenOff) {

    						cordova.plugins.backgroundMode.unlock();
    					}
					});

					this.watcher = navigator.geolocation.watchPosition(
						this.watchSuccessHandler.bind(this),
						this.watchErrorHandler.bind(this),
						this.options
					); 

					this.isEnabled = true;
					resolve();
				})
				.catch(err => {
					reject(err);
				})
		});
	}

	disable() {
		return new Promise((resolve, reject) => {
			this.disableBackgroundMode();

			navigator.geolocation.clearWatch(this.watcher);
			this.isEnabled = false;
			if (typeof device != "undefined" && device.platform == "iOS") {
				window.plugins.insomnia.allowSleepAgain();
			}
			resolve();
		});
	}

	toggle() {
		return this.isEnabled ? this.disable() : this.enable();
	}

	watchSuccessHandler(position) {
		this.history.push(position);

		// Trigger custom handlers
		this.successHandlers.forEach(handler => handler(position, this));
		this.lastPosition = position;
	}

	watchErrorHandler(positionError) {
		var errors = {
			1: this.getTranslation('notice.permissionDenied'),
			2: this.getTranslation('notice.positionNotAvailable'),
			3: this.getTranslation('notice.establishingYourPosition')
		};
		console.warn(errors[positionError.code]);

		// Trigger custom handlers
		this.errorHandlers.forEach(handler => handler(positionError, this));
	}

	requestLocationPermission() {
		return new Promise((resolve, reject) => {
			if (typeof cordova == "undefined") {
				resolve();
			}

			// consultar permiso de ubicacion
			cordova.plugins.diagnostic.getLocationAuthorizationStatus(status => {

				switch(status) {
					
					// Solicitar permiso
					case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:

						navigator.notification.confirm(
							this.getTranslation('request.location.permission'), // message
							res => {
								if (res == 1)
								{
									cordova.plugins.diagnostic.requestLocationAuthorization(
										newStatus =>{
						
											switch(newStatus){
						
												case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
													reject(this.getTranslation('notice.permissionDenied'));
												break;
						
												case cordova.plugins.diagnostic.permissionStatus.GRANTED:
													if(device.platform === "iOS"){
														console.log("Location services is already switched ON");
													}else{
														this.makeRequest();
													}
													resolve();
												break;
						
												case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
													if(device.platform === "iOS"){
														console.log("Location services is already switched ON");
													}else{
														this.makeRequest();
													}
													resolve();
												break;
											}
										}, error => {
											console.error(error);
										}, cordova.plugins.diagnostic.locationAuthorizationMode.ALWAYS
									);
								}
								else{
									reject(this.getTranslation('notice.userDeniedPermissionLocation'));
								}
							},
							this.getTranslation('location.permission'),// title
							["OK", this.getTranslation('action.cancel')]
						);	
						
					break;

					// Permiso denegado
					case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
						console.log("Permission denied");
						navigator.notification.confirm(
							this.getTranslation('notice.enableLocalizationToGetPosition'), 
							res => {
								if (res == 1) {
									device.platform == "iOS" ? cordova.plugins.diagnostic.switchToSettings() : cordova.plugins.diagnostic.switchToLocationSettings();
								} else {
									reject(this.getTranslation('notice.userDeniedPermissionLocation'))
								}
							}, 
							this.getTranslation('notice.positionNotAvailable'), 
							[this.getTranslation('noun.settings'), this.getTranslation('action.cancel')]
						);
					break;

					// Permiso otorgado
					case cordova.plugins.diagnostic.permissionStatus.GRANTED:
						if(device.platform == "iOS"){
							console.log("Location services is already switched ON");
						}else{
							this.makeRequest();
						}
						resolve();
					break;
					
					case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
						if(device.platform == "iOS"){
							console.log("Location services is already switched ON");
						}else{
							this.makeRequest();
						}
						resolve();
					break;
				}
			}, error =>{
				reject(error);
			});

		});
	}

	/* Background mode */
	enableBackgroundMode() {
		if (typeof cordova != "undefined") {
			if (!cordova.plugins.backgroundMode.isActive()) {
				cordova.plugins.backgroundMode.enable();
				cordova.plugins.backgroundMode.overrideBackButton();

				cordova.plugins.backgroundMode.setDefaults({
				    title: this.getTranslation('notice.transport'),
				    text: this.getTranslation('notice.location.permission')
				});
			}

			cordova.plugins.backgroundMode.on('activate', function() {
				cordova.plugins.backgroundMode.disableWebViewOptimizations();
			});
		}
	}

	disableBackgroundMode() {
		if (typeof cordova != "undefined") {
			cordova.plugins.backgroundMode.disable();
		}
	}

	makeRequest(){

		return new Promise((resolve, reject) => {
			if (typeof cordova == "undefined") {
				resolve();
			}

			cordova.plugins.locationAccuracy.canRequest(
				canRequest =>{
					if (canRequest) {
						cordova.plugins.locationAccuracy.request(
							success => {
								console.log("Location accuracy request successful "+success.message);
							}, 
							error => 
							{
								reject("Error requesting location accuracy: " + JSON.stringify(error));
								
								if (error) {
									// Android only
									reject("error code=" + error.code + "; error message=" + error.message);
									if (device.platform === "Android" && error.code !== cordova.plugins.locationAccuracy.ERROR_USER_DISAGREED) {
										if (window.confirm("Failed to automatically set Location Mode to 'High Accuracy'. Would you like to switch to the Location Settings page and do this manually?")) {
											cordova.plugins.diagnostic.switchToLocationSettings();
										}
									}
								}
							}, cordova.plugins.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY // iOS will ignore this
						);
					} else {
						// On iOS, this will occur if Location Services is currently on OR a request is currently in progress.
						// On Android, this will occur if the app doesn't have authorization to use location.
						reject("Cannot request location accuracy");
					}
			});
		});
	}
}