<template>
	<div class="phi-letter-avatar">
        {{initials}}
	</div>
</template>

<script>
import PhidiasColorUtils from "../../libraries/phidias.js/lib/Colors.js";    

export default {
    name: "phi-letter-avatar",

	data() {
            return {
                colors: [],
                bgColor: '',
                txtColor: '',
                initials: ''
            }
        },

	props: {
            name: {
                type: String,
                required: true
            },
        
            id: {
                required: false,
                default: null
            },
        
            size: {
                type: Number,
                required: false,
                default: 42
            }
	},

	methods: {
            setInitials(){
                let nameSplit = this.name.split(" ");

                this.initials = "";

                nameSplit.forEach(namePart => {
                    if(this.initials.length < 2 && namePart.length > 0){
                        this.initials = this.initials + namePart.charAt(0).toUpperCase();
                    }
                });
            },
        
            setColors(){
                this.colors = PhidiasColorUtils.getColorsList(0);
                
                this.bgColor = this.colors[Math.floor(this.id % this.colors.length)];
                this.txtColor = PhidiasColorUtils.getTextColorForBG(this.bgColor);

                this.$el.style.setProperty('--bg-color', this.bgColor);
                this.$el.style.setProperty('--txt-color', this.txtColor);
            },

            setSize(){
                this.$el.style.setProperty('--ln-height', this.size + "px");
                this.$el.style.setProperty('--fnt-size', (this.size * 0.50) + "px");
            }
	},

    mounted () {
        this.setSize();
        this.setInitials();
        this.setColors();
    }
}
</script>

<style lang="scss">
.phi-letter-avatar{
    --bg-color: #ccc;
    --txt-color: #ccc;
    --fnt-size: 10px;
    --ln-height: 42px;
    
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    
    text-align: center;
    vertical-align: middle;
    font-family: "Arial";
    font-size: var(--fnt-size);
    line-height: var(--ln-height);
    
    background-color: var(--bg-color);
    color: var(--txt-color);
}
</style>