export default {
	"common.Accept": "Aceptar",
	"common.Add": "Agregar",
	"common.Cancel": "Cancelar",
	"common.Close": "Cerrar",
	"common.Delete": "Eliminar",
	"common.Save": "Guardar",

	"common.accept": "aceptar",
	"common.add": "agregar",
	"common.cancel": "cancelar",
	"common.close": "cerrar",
	"common.delete": "eliminar",
	"common.save": "guardar",

	"action.accept": "Aceptar",
	"action.add": "Agregar",
	"action.addCheckpoint": "Agregar portal",
	"action.addCondition": "Agregar condición",
	"action.addNewRule": "Agregar nueva regla",
	"action.addToCalendar": "Marcar en el calendario",
	"action.addVehicle": "Adicionar ruta",
	"action.allowReplies": "Permitir respuestas",
	"action.allowRepliesAll": "Permitir respuestas a todos",
	"action.allowForward": "Permitir Reenviar",
	"action.archive": "archivar",
	"action.attach": "Adjuntar",
	"action.cancel": "Cancelar",
	"action.clearCache": "Limpiar caché",
	"action.close": "cerrar",
	"action.createNewType": "crear nuevo tipo",
	"action.delete": "eliminar",
	"action.deselect": "eliminar la selección",
	"action.Download": "Descargar",
	"action.enabled": "Habilitar",
	"action.form.addField": "agregar campo",
	"action.form.RemoveField": "Elminar este campo",
	"action.Forward": "Reenviar",
	"action.forward": "reenviar",
	"action.HideRecipients": "Ocultar destinatarios",
	"action.highlightPost": "Publicar en cartelera",
	"action.logout": "Cerrar sesión",
	"action.markRead": "marcar leído",
	"action.markUnread": "marcar no leído",
	"action.notify": "Notificar",
	"action.pay": "Pagar",
	"action.post.addAttachment": "Adjuntar",
	"action.redact": "Redactar",
	"action.repeatEvery": "repetir cada",
	"action.repeatOn": "repetir en",
	"action.reply": "Responder",
	"action.replyAll": "Responder a todos",
	"action.restore": "restaurar",
	"action.schedulePublishDate": "Programar fecha de envío",
	"action.scheduleExpirationDate": "Programar fecha de vencimiento",
	"action.save": "Guardar",
	"action.select": "seleccionar",
	"action.send": "Enviar",
	"action.sendAnswers": "Enviar respuestas",
    "action.sendAnswer": "Enviar respuesta",
	"action.Share": "Compartir",
	"action.slideGps" : "Deslice para iniciar GPS",
	"action.undo": "deshacer",
	"action.tapHereToUploadFiles":"Toca aqui para subir archivos",
	"action.track": "Rastrear",
	"adj.allDay": "todo el día",
	"adj.Appreciation.expelled": "expulsado",
	"adj.Appreciation.mustRepeat": "debe repetir",
	"adj.Appreciation.promoted": "promovido",
	"adj.Appreciation.toDefine": "por definir",
	"adj.ends": "finaliza",
	"adj.everyDay": "cada día",
	"adj.everyMonth": "cada mes",
	"adj.everyWeek": "cada semana",
	"adj.everyYear": "cada año",
	"adj.notIssued.plural": "No expedidos",
	"adj.read.plural": "leídos",
	"adj.repeats": "se repite",
	"adj.selected": "seleccionado",
	"adj.statusActive": "Activo",
	"adj.statusGraduated": "Egresado",
	"adj.statusInscribed": "Inscrito",
	"adj.statusPending": "Pendiente",
	"adj.statusSuspended": "Suspendido",
	"adj.statusWithdrawn": "Retirado",
	"adj.unread.plural": "no leídos",
	"adj.unread.singular": "no leído",
	"brother": "hermano",
	"component.phi.debit.expirationDate" : "Vence",
	"component.phi.debit.interests" : "Intereses",
	"component.phi.debit.issueDate" : "Expedido",
	"compose.placeholder": "Di algo ...",
	"condition.attributes.between": "entre",
	"condition.attributes.contains": "contiene",
	"condition.attributes.document": "Documento",
	"condition.attributes.equalTo": "igual a",
	"condition.attributes.firstName": "Nombre",
	"condition.attributes.gender": "Género",
	"condition.attributes.greaterThan": "mayor que",
	"condition.attributes.lastName": "Apellido",
	"condition.attributes.lessThan": "menor que",
	"condition.attributes.startsWith": "empieza con",
	"cumulatives": "acumuladas",
	"employee": "colaborador",
	"everyone": "todos",
	"exactly": "exactamente",
	"father": "padre",
	"graded equal to": "calificado igual a",
	"graded greater than or equal to": "calificado con más de o igual a",
	"graded greater than": "calificado con más de",
	"graded less than or equal to": "calificado con menos de o igual a",
	"graded less than": "calificado con menos de",
	"grades": "notas",
	"grade": "Nota",
	"group director": "Director de grupo",
	"here": "aquí",
	"I did not find anyone": "No encontré a nadie",
	"less than or exactly": "menos de o exactamente",
	"less than": "menos de",
	"member": "Grupos",
	"more people": "ver más",
	"more than or exactly": "más de o exactamente",
	"more than": "más de",
	"mother": "madre",
	"nav.calendar": "Calendario",
	"nav.contacts": "Contactos",
	"nav.highlights": "Cartelera",
	"nav.inbox": "Entrada",
	"nav.school": "Más",
	"no": "No",
	"noone": "ninguno",
	"notice.loading": "Cargando",
	"notice.noPeopleSelected": "Debes seleccionar destinatarios",
	"notice.readByReadOfTotal": "leído por {{read}} de {{total}}",
	"notice.thereIsNothingHere": "No hay nada aquí",
	"notice.toMe": "para mí",
	"notice.ToNPeople": "Para {{n}} personas",
	"notice.ToOnePerson": "Para 1 persona",
	"notice.tuCompra" : "Usted retorna de una transacción en línea, recuerde que el sistema puede tardar un tiempo en reflejar el resultado de la transacción",

	"notice.geolocalizationNotSupported": "Geolocalización no es soportada en este navegador",
	"notice.permissionDenied": "Permiso denegado",
	"notice.positionNotAvailable": "Posición no disponible",
	"notice.establishingYourPosition": "Estableciendo su ubicación",
	"notice.generalFailure": "Fallo general",
	"notice.gpsSuccessfullEnable": "GPS activo",
	"notice.gpsEnable": "Activar GPS",
	"notice.gpsDisable": "Desactivar GPS",
	"notice.gpsLocationAccuracy": "Por favor habilite el GPS en su dispositivo para una ubicación más precisa",
	"notice.errorLocationAccuracy": "Error al solicitar precisión de posición",
	"notice.userDeniedPermissionLocation": "El usuario ha denegado el acceso a conocer su ubicación",
	"notice.enableLocalizationToGetPosition": "Determinar tu ubicación",
	"notice.starting a": "Iniciando la",
	"notice.routeFinish": "Finaliza la",
	"notice.lastRecharge": "Última recarga",
	"notice.lastNRecharges": "Últimas {{n}} recargas realizadas via Internet",
	"notice.withoutRecharges": "Sin recargas",

	"noun.academicGroups": "Grupos",
	"noun.academicYear": "Año",
	"noun.administration": "Administración",
	"noun.addEmail": "Agregar Correo",
	"noun.agenda": "Agenda",
	"noun.all.plural": "todos",
	"noun.archived.plural": "Archivados",
	"noun.Balance": "Balance",
	"noun.bcc": "Bcc",
	"noun.billing": "Tesorería",
    "noun.busy": "ocupado",
	"noun.calendar": "Calendario",
	"noun.checkpoints": "Portales",
	"noun.Checked": "Seleccionado",
	"noun.Unchecked": "No seleccionado",
	"noun.conceptsToPay": "Conceptos a pagar",
	"noun.dashboard": "Inicio",
	"noun.day": "día",
	"noun.dayOfMonth": "día del mes",
	"noun.dayOfWeek": "día de la semana",
	"noun.days": "días",
	"noun.defaultValue": "Predeterminado",
	"noun.deleteEmail": "¿Desea eliminar este correo electrónico y todas sus preferencias de notificación?",
	"noun.document": "Documento",
	"noun.editable": "Editable",
	"noun.Employees": "Empleados",
	"noun.email":"Correo",
	"noun.files": "Archivos",
	"noun.form": "Formulario",
	"noun.form.description": "descripción",
	"noun.form.description": "descripción",
	"noun.form.options": "opciones",
	"noun.form.title": "título",
	"noun.form.typeCheckbox": "checkbox",
	"noun.form.typeSelect": "lista",
	"noun.form.typeText": "texto",
	"noun.form.typeTextarea": "texto amplio",
	"noun.general": "General",
	"noun.googleDrive": "Documentos de Google Drive",
	"noun.groups": "Grupos",
	"noun.inbox": "Bandeja de entrada",
	"noun.HTMLText": "Texto HTML",
	"noun.language": "Idioma",
	"noun.Mentions": "Menciones",
	"noun.map" : "Mapa",
	"noun.modality": "Modalidad",
	"noun.month": "Mes",
	"noun.months": "meses",
	"noun.mySubscriptions": "Mis subscripciones",
	"noun.mobileApp":"Aplicación móvil",
	"noun.name": "Nombre",
	"noun.namePlural": "Plural",
	"noun.nameSingular": "Singular",
	"noun.never": "nunca",
	"noun.none": "ninguno",
	"noun.notifications": "Notificaciones",
	"noun.nounPersonFemale": "La",
	"noun.nounPersonMale": "El",
	"noun.payedConcepts": "Conceptos pagados",
	"noun.paymentsToApply": "Pagos por aplicar",
	"noun.pendingCharges": "Cobros pendientes",
	"noun.People": "Personas",
	"noun.periods": "Periodos",
	"noun.post.preview": "vista previa",
	"noun.post.modifyFiles": "modificar archivos",
	"noun.post.modifyPicture": "modificar imagen",
	"noun.post.delete": "eliminar",
	"noun.post.edit": "editar",
	"noun.post.editVideo": "Editar video",
	"noun.post.selectDocument": "Seleccionar documento",
	"noun.post.Title": "Asunto",
	"noun.postTypes": "Tipos de entrada",
	"noun.prices": "precios",
	"noun.recentPayments": "Pagos recientes",
	"noun.Relatives": "Familiares",
	"noun.righNow": "inmediato",
	"noun.section": "Sección",
	"noun.sent.plural": "Enviados",
	"noun.sentTo.singular": "Enviado a",
	"noun.settings": "Configuración",
	"noun.status": "Estado",
	"noun.studentCode": "Código",
	"noun.Students": "Estudiantes",
	"noun.to": "Para",
	"noun.targets": "Rutas",
	"noun.tracker": "Rastreador",
	"noun.transport": "Transporte",
	"noun.trash": "Papelera",
	"noun.type": "Tipo",
	"noun.urlVideo": "URL de youtube o vimeo",
	"noun.urlVideoError": "Esta no parece una URL de video válida",
	"noun.value": "valor",
	"noun.validEmail": "El correo electrónico no tiene un formato válido",
	"noun.view": "Vista",
	"noun.video": "Video",
	"noun.week": "Semana",
	"noun.weeks": "semanas",
	"noun.years": "años",
	"OK": "OK",
	"person.condition.attributes":               "Datos personales",
	"person.condition.debtor":                   "Estado financiero",
	"person.condition.enrollment":               "Matrícula",
	"person.condition.type":                     "Tipo",
	"person.condition.v3Appreciation":           "Valoracón Final",
	"person.condition.v3ChildrenWithEnrollment": "Hijos en curso",
	"person.condition.v3Class":                  "Clase",
	"person.condition.v3Debit":                  "Facturación",
	"person.condition.v3DebitPrice":             "Facturación por precio",
	"person.condition.v3EmployeeGroups":         "Grupo (colaborador)",
	"person.condition.v3FamilyWithXChildren":    "En familias con N hijos",
	"person.condition.V3Mentions":               "Menciones",
	"person.condition.v3Role":                   "Rol",
	"person.condition.v3Transport":              "Transporte",
	"person.condition.V3EnrollmentModality": 	 "Modalidad",
	"pick prices": "seleccione precios",
	"post.noun.everything": "todo",
	"post.settings.highlightOff" : "No publicado en cartelera",
	"post.settings.highlightOn" : "Publicado en cartelera",
	"post.settings.notificationsOff" : "Notificaciones desactivadas",
	"post.settings.notificationsOn" : "Notificaciones activadas",
	"post.settings.recipientsInvisible" : "Destinatarios ocultos",
	"post.settings.recipientsVisible" : "Destinatarios visibles",
	"post.settings.repliesOff" : "Respuestas desactivadas",
	"post.settings.repliesOn" : "Respuestas activadas",
	"post.settings.repliesAllOn" : "Respuestas a todos activadas",
	"post.settings.repliesAllOff" : "Respuestas a todos desactivadas",
	"post.settings.forwardOn" : "Reenviar activado",
	"post.settings.forwardOff" : "Reenviar desactivado",
	"prep.until": "hasta",
	"published": "publicado",
	"question.deleteThisEvent": "¿Eliminar este evento?",
	"question.deleteThisFile": "¿Eliminar estos archivos?",
	"question.deleteThisRule": "¿Eliminar esta regla?",
	"question.discardThisDraft": "¿Descartar este borrador?",
	"question.deleteThisVideo": "¿Eliminar este video?",
	"question.deleteThisText": "¿Eliminar este texto?",
	"question.deleteThisForm": "¿Eliminar este formulario?",
	"search": "buscar",
	"sister": "hermana",
	"teacher": "Profesor",
	"substitute teacher": "Profesor Suplente",
	"Total": "Total",
	"try again": "inténtalo de nuevo",
	"type.Visible": "Visible en listados",
	"type.Invisible": "Oculto en listados",
	"yes": "Si",
    "adj.statusInactive" : "Inactivo",
    "atendant": "acudiente",
    "attendance": "Asistencia",
    "aunt": "tia",
    "daughter": "hija",
    "female cousin": "prima",
    "granddaughter": "nieta",
    "grandfather": "abuelo",
    "grandmother": "abuela",
    "grandson": "nieto",
    "male cousin": "primo",
    "nephew": "sobrino",
    "niece": "sobrina",
    "noun.employee-group" : "Grupo Colaborador",
    "other": "otro",
    "person.condition.V3PeopleSetMemberships":   "Grupo (en todos)",
    "relative": "familiar",
    "representant": "representante",
    "responsible": "responsable",
    "searching": "buscando",
    "son": "hijo",
    "stepdaughter": "hijastra",
    "stepfather": "padrastro",
    "stepmother": "madrastra",
    "stepson": "hijastro",
    "student": "estudiante",
    "startRoute": "Iniciar recorrido",
    "uncle": "tio",
    "confirm.deleteFile":"¿Realmente quieres borrar este archivo adjunto?",
    "events": "eventos",
    "assignments": "Tareas",
    "exams": "Exámenes",
	"evaluations": "evaluaciones",

	"register.continueToProcess": "Consultar procesos",
	"register.form.addRelative": "Registrar familiar",
	"register.form.addStudent": "Registrar estudiante",
	"register.form.birthday": "Fecha de nacimiento",
	"register.form.cancel": "Cancelar",
	"register.form.children": "Hijos",
	"register.form.children2": "Hijos",
	"register.form.course": "Curso",
	"register.form.course.pick": "-- Seleccionar curso --",
	"register.form.email": "eMail",
	"register.form.error.birthday.empty": "Debes seleccionar una fecha",
	"register.form.error.children.empty": "Debes seleccionar al menos un hijo",
	"register.form.error.course.empty": "Debes seleccionar un curso",
	"register.form.error.email.empty": "Debes escribir un eMail",
	"register.form.error.email.invalid": "eMail inválido",
	"register.form.error.email.taken": "Este eMail ya está siendo utilizado",
	"register.form.error.document.empty": "Debes escribir un documento",
	"register.form.error.firstName.empty": "Debes escribir un nombre",
	"register.form.error.lastName.empty": "Debes escribir un apellido",
	"register.form.error.password.empty": "Debes escribir una contraseña",
	"register.form.error.password.mismatch": "La contraseña no coincide",
	"register.form.document": "Documento",
	"register.form.firstName": "Nombre",
	"register.form.gender.female": "Femenino",
	"register.form.gender.male": "Masculino",
	"register.form.gender": "Sexo",
	"register.form.lastName": "Apellido",
	"register.form.lastName2": "Segundo apellido",
	"register.form.loading": "Registrando ...",
	"register.form.password": "Contraseña",
	"register.form.submit": "Registrar",
	"register.form.success.email": "Hemos enviado un correo a <strong>{{email}}</strong> con instrucciones para continuar el proceso",
	"register.form.success.title": "Revisa tu eMail!",
	"register.form.verifyPassword": "Verificar contraseña",
	"tracking": "Rastreando",
	"transport.email": "eMail",
	"transport.sms": "SMS",
	"transport.apn": "App (iOS)",
	"transport.gcm": "App (Android)",

	"launcher.Website": "Página web",
	"launcher.CallSchool": "Llamar al colegio",
	"launcher.Location": "Ubicación",
	"launcher.billing": "Tesorería",
	"launcher.messaging": "Mensajería",
	"launcher.transport": "Transporte",

	"downloading":"Descargando",
	"error downloading file":"Error Descargando Archivo",

	"credits.l1": "Desarrollado por Phidias SAS",
    "credits.l2": "para",
    "credits.l3": "Todos los derechos reservados",
    "clinic.blood_type": "Tipo de sangre",
    "clinic.weight": "Peso",
    "clinic.height": "Estatura",
    "clinic.medical_insurance_EPS": "Medicina prepagada y/o EPS",
	"noun.mobile": "Celular",
	"noun.birthday": "Fecha de Nacimiento",

	// terminos notificaciones singular
	"Ausencia": "Ausencia",
  "Aviso Urgente": "Aviso Urgente",
  "Boletin": "Boletin",
  "Circular": "Circular",
  "clasificado": "clasificado",
  "cobro": "cobro",
  "Comunicado": "Comunicado",
  "Documento": "Documento",
  "Encuesta": "Encuesta",
  "Evento": "Evento",
  "Mensaje": "Mensaje",
  "Noticia": "Noticia",
  "notificacion": "notificacion",
  "novedad de transporte": "novedad de transporte",
  "pago": "pago",
  "proceso": "proceso",
  "reunion": "reunion",
  "seguimiento": "seguimiento",
  "tarea": "tarea",

	// terminos notificaciones plural
	"Ausencias": "Ausencias",
	"Avisos Urgentes": "Avisos Urgentes",
	"Boletines": "Boletines",
	"Circulares": "Circulares",
	"clasificados": "clasificados",
	"cobros": "cobros",
	"Comunicados": "Comunicados",
	"Documentos": "Documentos",
	"Encuestas": "Encuestas",
	"Eventos": "Eventos",
	"mensajes": "mensajes",
	"Noticias": "Noticias",
	"notificaciones": "notificaciones",
	"novedades de transporte": "novedades de transporte",
	"pagos": "pagos",
	"procesos": "procesos",
	"reuniones": "reuniones",
	"seguimientos": "seguimientos",
	"tareas": "tareas",

  "launcher.classroom": "Aula",
  "the message is expired": "el mensaje está vencido",

	"privacy policies": "Políticas de privacidad",
	"read privacy policies": "Leer políticas de privacidad",
	"attached files": "Archivos adjuntos",
	"accept privacy policies": "Aceptar politicas de privacidad",
	"export to csv": "Exportar a csv",
	"show": "Mostrar",
	"show": "Mostrar",
}
