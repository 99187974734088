import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

/* Set up routes */
const Code = () => import('@/states/Code.vue');
const Login = () => import('@/states/Login.vue');
const Deck = () => import('@/states/Deck.vue');
const Id = () => import('@/states/Id.vue');
const Hidden = () => import('@/states/Hidden.vue');
const Launcher = () => import('@/states/Launcher.vue');
const Highlights = () => import('@/states/Highlights.vue');
const Inbox = () => import('@/states/Inbox.vue');
// const ThreadFeed = () => import('@/states/Thread/Feed.vue');
const ThreadFeed = () => import('@/states/Thread2/Feed.vue');
const Thread = () => import('@/states/Thread/Thread.vue');
const PostCompose = () => import('@/states/Post/Compose.vue');
const PostSent = () => import('@/states/Post/Sent.vue');
const PostStubs = () => import('@/states/Post/Stubs.vue');
const Calendar = () => import('@/states/Calendar.vue');
const Settings = () => import('@/states/Settings.vue');
const SettingsNotifications = () => import('@/states/Settings/Notifications.vue');
const Nodes = () => import('@/states/Nodes.vue');
const NodeContainer = () => import('@/states/Node/Container.vue');
const NodeDashboard = () => import('@/states/Node/Dashboard.vue');
const NodePosts = () => import('@/states/Node/Posts.vue');
const NodePeople = () => import('@/states/Node/People.vue');
const NodeNodes = () => import('@/states/Node/Nodes.vue');
const NodeImport = () => import('@/states/Node/Import.vue');
const People = () => import('@/states/People.vue');
const Person = () => import('@/states/Person.vue');
const PostTypes = () => import('@/states/Post/Types.vue');
const PostTypesEdit = () => import('@/states/Post/Types/Edit.vue');
const AttendanceAdmin = () => import('@/states/Attendance/Admin.vue');
const AttendanceCheckpoint = () => import('@/states/Attendance/Checkpoint.vue');
const TransportRoutes = () => import('@/states/Transport/Routes.vue');
const TransportRouteLog = () => import('@/states/Transport/Route/Log.vue');
const TransportRouteMap = () => import('@/states/Transport/Route/Map.vue');
const GeoTracker = () => import('@/states/Geo/Tracker.vue');
const GeoFeed = () => import('@/states/Geo/Feed.vue');
const GeoTargets = () => import('@/states/Geo/Targets.vue');
const Billing = () => import('@/states/Billing/Billing.vue');
const DebitDetails = () => import('@/states/Billing/Debit/Details.vue');
const CreditDetails = () => import('@/states/Billing/Credit/Details.vue');

/* Classroom */
const PersonClassroom = () => import('@/states/Person/Classroom.vue');
const PersonClassroomGroup = () => import('@/states/Person/Classroom/Group.vue');
const PersonClassroomPost = () => import('@/states/Person/Classroom/Post.vue');
const Classroom = () => import('@/states/Classroom.vue');

/* Children (Hijos) */
const PersonChildren = () => import('@/states/Person/Children.vue');

/* Teacher dashboard */
const Teaching = () => import('@/states/Teaching/Teaching.vue');

/* Alternate calendar */
const TestCalendar = () => import('@/states/Test/Calendar.vue');

/* Tests */
const TestPopups = () => import('@/states/Test/Popups.vue');
const TestDatepicker = () => import('@/states/Test/Datepicker.vue');

/* Data Entities */
const StateDataDashboard = () => import('@/states/Data/Dashboard.vue');


/* Dev test */
const SebasDev = () => import('@/states/Sebas.vue');
const FelipeDev = () => import('@/states/Felipe.vue');
const JonatanDev = () => import('@/states/jonatan.vue');
const TebanDev = () => import('@/states/Teban.vue');
const DanielDev = () => import('@/states/Daniel.vue');
const LeoDev = () => import('@/states/Leo.vue');
const LucasDev = () => import('@/states/Lucas.vue');

let router = new VueRouter({
    routes: [
        { path: '/', redirect: '/highlights' },
        { path: '/code', component: Code, name: 'code', meta: { isPublic: true, exitOnBack: true } },
        { path: '/login', component: Login, name: 'login', meta: { isPublic: true, exitOnBack: true } },

        {
            path: '/deck', component: Deck,
            children: [
                {
                    path: '/highlights', component: Highlights, meta: {order: 1, exitOnBack: true }, name: 'highlights',
                    children: [
                        { path: '/threads/feed/:threadId', component: Thread, meta: {order: 11}, name: 'highlight-thread' },
                    ]
                },

                { path: '/dashboard/:dashboardId', component: StateDataDashboard, meta: {order: 2 }, name: "data-dash", props: true },

                { path: '/inbox', component: Inbox, meta: {order: 1, exitOnBack: true }, name: "inbox" },
                {
                    path: '/calendar', component: Calendar, meta: {order: 1, exitOnBack: true}, name: 'calendar',
                    children: [
                        { path: '/threads/feed/:threadId', component: Thread, meta: {order: 11}, name: 'calendar-thread' },
                    ]
                },
                { path: '/launcher', component: Launcher, meta: {order: 1, exitOnBack: true } },

                { path: '/id', component: Id, meta: {order: 1 } },

                { path: '/hidden', component: Hidden },

                {
                    path: '/threads/feed', component: ThreadFeed, props: {folder: 'inbox'}, meta: {order: 10}, name: 'feed',
                    children: [
                        { path: '/threads/feed/:threadId', component: Thread, meta: {order: 11}, name: 'thread' },
                    ]
                },

                { path: '/archive', component: ThreadFeed, props: {folder: 'archive'}, meta: {order: 3}, name: 'archive' },
                { path: '/trash', component: ThreadFeed, props: {folder: 'trash'}, meta: {order: 3}, name: 'trash' },
                { path: '/compose', component: PostCompose, meta: {order: 3}, name: 'compose' },
                { path: '/sent', component: PostSent, meta: {order: 3}, name: 'sent' },
                { path: '/sent/:postId', component: PostStubs, meta: {order: 4}, name: 'post-stubs' },

                // Classroom
                { path: '/classroom', component: Classroom, name: 'pclassroom', meta: {order: 1, exitOnBack: true}},
                { path: '/people/:personId/classroom', component: PersonClassroom, name: 'classroom', meta: {order: 2}},
                { path: '/people/:personId/classroom/:groupId', component: PersonClassroomGroup, name: 'classroom-group', meta: {order: 2}},
                { path: '/people/:personId/classroom/:groupId/posts/:postId', component: PersonClassroomPost, name: 'classroom-post', meta: {order: 2}},

                // Children
                { path: '/people/:personId/children', component: PersonChildren, name: 'person-children', meta: {order: 3}},

                // Teaching
                { path: '/teaching/:personId', component: Teaching, name: 'teaching', meta: {order: 2}},

                // Billing
                { path: '/billing/:personId', component: Billing, name: 'billing', meta: {order: 2}},
                { path: '/billing/debits/:debitId', component: DebitDetails, name: 'billing-debit-debitId', meta: {order: 2} },
                { path: '/billing/credits/:creditId', component: CreditDetails, name: 'billing-credit-creditId', meta: {order: 2} },

                { path: '/people', component: People, meta: {order: 10}, name: 'people' },
                { path: '/person/:personId', component: Person, meta: {order: 11}, name: 'person' },

                // Settings
                { path: '/settings', component: Settings, meta: {order: 12}, name: 'settings' },
                { path: '/settings/notifications', component: SettingsNotifications, meta: {order: 15}, name: 'settings-notifications' },

                // Admin
                { path: '/post/types', component: PostTypes, meta: {order: 2}, name: 'post-types' },
                { path: '/post/types/:type', component: PostTypesEdit, meta: {order: 2}, name: 'post-type-edit' },

                // Attendance
                { path: '/attendance/admin', component: AttendanceAdmin, meta: {order: 2}, name: 'attendance-admin' },
                { path: '/attendance/checkpoint/:nodeId', component: AttendanceCheckpoint, meta: {order: 3}, name: 'attendance-checkpoint' },

                // Transport attendance
                { path: '/transport/routes/', component: TransportRoutes, meta: {order: 2}, name: 'transport-routes' },
                { path: '/transport/routes/:routeId/log', component: TransportRouteLog, meta: {order: 3}, name: 'transport-route-log' },
                { path: '/transport/routes/:routeId/map', component: TransportRouteMap, meta: {order: 4}, name: 'transport-route-map' },

                // Geolocation
                { path: '/geo/targets', component: GeoTargets, meta: {order: 1}, name: 'geo-targets' },
                { path: '/geo/targets/:targetId', component: GeoTracker, meta: {order: 1}, name: 'geo-tracker' },
                { path: '/geo/feed', component: GeoFeed, name: 'geo-feed' },

                // Nodes
                { path: '/nodes', component: Nodes, meta: {order: 1}, name: 'nodes' },
                {
                    path: '/nodes/:nodeId', component: NodeContainer, meta: {order: 13 },
                    children: [
                        { path: '', component: NodeDashboard, meta: {order: 14}, name: 'node' },
                        { path: 'posts/:type', component: NodePosts, meta: {order: 15}, name: 'node-posts' },
                        { path: 'people', component: NodePeople, meta: {order: 16}, name: 'node-people' },
                        { path: 'nodes', component: NodeNodes, meta: {order: 17}, name: 'node-nodes' },
                        { path: 'import', component: NodeImport, meta: {order: 18}, name: 'node-import' }
                    ]
                },

                // Alternate calendar
                {
                    path: '/test/calendar', component: TestCalendar, meta: {order: 1, exitOnBack: true}, name: 'test-calendar',
                    children: [
                        { path: '/calendar/thread/:threadId', component: Thread, meta: {order: 1}, name: 'event-thread' },
                    ]
                },

                // Tests
                { path: '/test/popups', component: TestPopups, meta: {order: 1}, name: 'test-popups' },
                { path: '/test/datepicker', component: TestDatepicker, meta: {order: 1}, name: 'test-datepicker' },
                

                { path: '/sebas', component: SebasDev },
                { path: '/felipe', component: FelipeDev },
                { path: '/jonatan', component: JonatanDev },
                { path: '/teban', component: TebanDev },
                { path: '/daniel', component: DanielDev },
                { path: '/leo', component: LeoDev },
                { path: '/lucas', component: LucasDev }

            ]
        },

    ]
});


/*
Navigation guards:
redirect to /code if app is not initialized,
redirect to /login if user is not authenticated
*/
import store from '@/store/index.js';

router.beforeEach((to, from, next) => {
    if (to.name != 'code' && !store.state.url) {
        next({ name: 'code' });
        return;
    }

    if (!to.meta.isPublic && !store.state.user) {
        next({ name: 'login' });
        return;
    }

    next();
});

router.afterEach((to, from) => {
   router.previousRoute = from;
});

export default router;