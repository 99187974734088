<template>
	<div class="phi-drawer" :class="{open: open, closed: !open}">
        <slot></slot>
	</div>
</template>

<script>
import Velocity from 'velocity-animate';

export default {
    name: "phi-drawer",

	props: {
		open: Boolean,
		slideDown: {
			default: () => ({
				duration: 620,
				easing: [500, 30]
			})
		},

		slideUp: {
			default: () => ({
				duration: 420,
				easing: [.42, 0, 0.2, 1]
			})
		},

		linear: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			isOpen: this.open
		}
	},

	methods: {
		toggle() {
			this.set(!this.isOpen);
		},

		set(isVisible) {
			this.isOpen = isVisible;
			if (isVisible) {
				Velocity(this.$el, "slideDown", this.velocityOptions.down);
			} else {
				Velocity(this.$el, "slideUp", this.velocityOptions.up);
			}
		}
	},

    mounted() {
		this.isOpen = this.open;
		this.$el.style.display = this.isOpen ? "block" : "none";
    },

    watch: {
        open() {
            this.set(this.open);
        }
    },

	computed: {
		velocityOptions() {
			return {
				up: this.linear ? {duration: 200} : this.slideUp,
				down: this.linear ? {duration: 240} : this.slideDown
			}
		}
	}
}

</script>

<style lang="scss">
.phi-drawer {
    // No se como, pero esto arregla el problema del "snap" al hacer slideUp
    border: 1px solid transparent;
    border-left: 0;
    border-right: 0;
}
</style>
