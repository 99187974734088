import Vue from 'vue';

function getScrollParent(element, includeHidden) {
    var style = getComputedStyle(element);
    var excludeStaticParent = style.position === "absolute";
    var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
    if (style.position === "fixed") return document;
    for (var parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
    }
    return document;
}

export default {
    bind(el, binding, vnode) {

        el.$phiStick = {
            container: null,
            bounds: {
                top: null,
                bottom: null
            },

            ghost: null,
            isOutside: false,
            boundHandler: null,

            initialize() {
                /* Determine initial bounds */
                el.initialStyle = {
                    position: el.style.position,
                    left:     el.style.left,
                    width:    el.style.width,
                    display:  getComputedStyle(el).display == 'block' ? 'block': 'inline-block'
                }

                this.container = getScrollParent(el);

                if (this.container == document) {
                    this.bounds.top    = 0;
                    this.bounds.bottom =  window.innerHeight;
                } else {
                    var rect           = this.container.getBoundingClientRect();
                    this.bounds.top    = rect.top;
                    this.bounds.bottom = rect.bottom;
                }

                /* Create ghost element */
                var coords = el.getBoundingClientRect();
                this.ghost = document.createElement(el.tagName);
                this.ghost.className = 'ghost';
                this.ghost.style.width = coords.width + 'px';
                this.ghost.style.height = coords.height + 'px';
                this.ghost.style.display = 'none';
                el.insertAdjacentElement('afterend', this.ghost);

                /* Register scroll listener */
                this.boundHandler = this.scrollHandler.bind(this);
                this.container.addEventListener("scroll", this.boundHandler);

                /* Run initial positioning */
                setTimeout(() => this.scrollHandler(), 1000);
            },

            destroy() {
                this.container.removeEventListener("scroll", this.boundHandler);
            },

            stick(position, coords) {
                el.style.position = 'fixed';
                el.style.left     = coords.left + 'px';
                el.style.width    = coords.width + 'px';

                if (position == 'top') {
                    el.style.top    = this.bounds.top + 'px';
                    el.style.bottom = 'auto';
                } else {
                    el.style.top    = 'auto';
                    el.style.bottom = (window.innerHeight - this.bounds.bottom) + 'px';
                }

                this.ghost.style.display = el.initialStyle.display; // show ghost
                el.classList.add('phi-stuck');
            },

            unstick() {
                el.style.position = el.initialStyle.position;
                el.style.left     = el.initialStyle.left;
                el.style.width    = el.initialStyle.width;

                this.ghost.style.display = 'none';
                el.classList.remove('phi-stuck');
            },

            scrollHandler() {
                var trackedElement   = this.isOutside ? this.ghost: el;
                var coords           = trackedElement.getBoundingClientRect();
                var currentlyOutside = coords.top < this.bounds.top ? 'top' : (coords.bottom > this.bounds.bottom ? 'bottom' : null);

                if (currentlyOutside != this.isOutside) {
                    this.isOutside = currentlyOutside;
                    this.isOutside ? this.stick(this.isOutside, coords) : this.unstick();

                    // This is a good place to look for dimension changes and resize the ghost accordingly
                    var coords              = el.getBoundingClientRect();
                    this.ghost.style.width  = coords.width + 'px';
                    this.ghost.style.height = coords.height + 'px';
                }
            }
        };

        // Initialize
        Vue.nextTick(() => el.$phiStick.initialize());
    },

    unbind(el) {
        el.$phiStick.destroy();
    }
}