<template>
    <div>
        <textarea style="min-height: 400px"></textarea>

        <mu-dialog
            scrollable
            :open="dialogIsOpen"
            @close="dialogIsOpen = false"
            dialog-class="dialogContainer"
            body-class="dialogBody"
        >
            <phi-filesystem-folder
                v-if="dialogIsOpen"
                :url="uploadsUrl"
                :api="app.api"
                @select="selectImage"
            ></phi-filesystem-folder>
        </mu-dialog>
    </div>
</template>

<script>
import app from '../store/app.js';
import PhiFilesystemFolder from './Phi/Filesystem/Folder.vue';

export default {
    name: "tiny-mce",

    components: {PhiFilesystemFolder},

    props: {
        value: {
            type: String,
            default: ''
        },

        configuration: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            app,
            uploadsUrl: app.user ? `/people/${app.user.id}/uploads` : '/people/anon/uploads',
            instance: null,
            content: null,
            dialogIsOpen: false,
            dialogCallback: null
        }
    },
        
    mounted() {
        if (typeof tinymce == 'undefined') {
            var tag = document.createElement('script');
            tag.id = 'script-tiny-mce';
            tag.src = 'tinymce/tinymce.min.js';
            tag.onload = () => {
                this.initializeTiny();
            }
            document.head.append(tag);
        } else {
            this.initializeTiny();
        }
    },

    methods: {
        initializeTiny() {
            tinymce.init(Object.assign({
                language: this.$store.state.i18n.language.replace('_', '-').split('-')[0],
                target: this.$el.querySelector('textarea'),
                branding: false,
                plugins: ['code link image imagetools colorpicker textcolor fullscreen textcolor table lists'],
                // force_br_newlines : true,
                // force_p_newlines : false,
                // forced_root_block : '',
                // invalid_elements : 'iframe',

                menubar: false,
                toolbar: ['formatselect | alignleft aligncenter alignright alignjustify forecolor backcolor | bold italic underline | bullist numlist | link image | styleselect removeformat | code'],

                imagetools_proxy: app.api.host + '/proxy/',

                file_picker_callback: (callback, value, meta) => {
                    this.dialogCallback = callback;
                    this.dialogIsOpen   = true;
                },

                images_upload_handler: (blobInfo, success, failure) => {
                    var data = new FormData;
                    data.append('file', blobInfo.blob(), blobInfo.filename());

                    fetch(app.api.host + this.uploadsUrl, {
                        method: 'POST',
                        body: data
                    })
                    .then(response => response.json())
                    .then(jsonResponse => success(jsonResponse[0].url))
                    .catch(error => failure('Error subiendo archivo'));
                }

            }, this.configuration))

            .then(instances => {
                this.instance = instances[0];
                if (this.value) {
                    this.instance.setContent(this.value);
                    // this.instance.setContent(this.value.replace(/\r?\n/g, '<br />'));
                }

                // El evento "change" de tinymce solo corre cuando hay un cambio en su historya undo/redo
                // y ademas se dispara al cerrar la ventana o salir de la pagina actual.
                // Lo mismo pasa con blur.
                // Eso esta causando que se disparen eventos DESPUES de haber enviado formularios via post, y causando problemas
                // asi que para detectar cambios, NO usar "change" ni "blur" sino...
                this.instance.on('KeyUp', () => {
                    this.$emit('input', this.instance.getContent());
                });

                this.instance.on('ExecCommand', () => {
                    this.$emit('input', this.instance.getContent());
                });

                this.instance.on('SetContent', () => {
                    this.$emit('input', this.instance.getContent());
                });

                //Se agrego este evento ya que al editar un enlace dentro de tiny no actualiza el mensaje 
                //y esto se usa mucho por algunos colegios para reeenviar mensajes grandes con diferentes enlaces
                // se agrega el evento NodeChange y se valida para que solo se ejecute en el caso de que sea un enlace =="<a href=>"
                this.instance.on('NodeChange', (e) => {
                    if(e.element.tagName.toLowerCase()==='a'){
                        this.$emit('input', this.instance.getContent());
                    }
                });
                

            });
        },

        selectImage(image) {
            // this.dialogCallback(image.preview);
            this.dialogCallback(image.url);
            this.dialogCallback = null;
            this.dialogIsOpen   = false;
        }
    },
}
</script>

<style lang="scss">
.dialogContainer {
    display: flex;
    flex-direction: column;
    max-width: none;
}

.dialogBody {
    flex: 1;

    .phi-filesystem-folder {
        .file-upload {
            margin: 12px 0;
        }
    }
}
</style>